import React from "react";
import { Container } from "reactstrap";

const NotFound = () => {

  return (
    <Container>
    404 NotFound
    </Container>
  );
};

export default NotFound;




