import React, { useEffect, useState,useContext } from "react";
import { userInfoContext } from "../App";
import axios from "axios";
import qs from "qs";
import { Container, Row, Col, CardBody,Card,CardTitle, Form, FormGroup, Label, Button, Input, Table, Alert, Badge, ListGroup, ListGroupItem,Modal,ModalBody,ModalHeader,ModalFooter } from "reactstrap";
import { CardDashboard } from "../styles/Card";
// get our fontawesome imports
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LieuIcon from "../img/lieu.svg";

import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId } from 'react-places-autocomplete';

function Lieu(props) {
    const member = props.member;
    //const userInfo = props.userInfo;
    const { userInfo, setUserInfo } = useContext(userInfoContext);
    const [lieuInfo, setLieuInfo] = useState(null);
    const [lieuaSupprimer, setLieuaSupprimer] = useState(null);
    const [formAddLieu, setFormAddLieu] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [formError, setFormError] = useState(false);
    const [modal, setModal] = useState(false);
    const [selectedLieu, setSelectedLieu] = useState(null);
    const [formEditLieu, setFormEditLieu] = useState(false);
    const [targetAdresse, setTargetAdresse] = useState(null);
    const [postalCode, setPostalCode] = useState("");

    const toggle = (id) => {
        setModal(!modal);
    }

    /* on recherche les lieu de l'entreprise */
   async function checkLieuEntreprise() {
   console.log(userInfo);
    console.log(userInfo.entreprise_id);
    if(userInfo.entreprise_id != undefined){
        const allLieux = [];
        const URLlieu = 'https://api.airtable.com/v0/appN12t0eZ3JSYwzx/lieu';
            const queryLieu = '?filterByFormula=';
            
            const filterByLieu = `SEARCH("${userInfo.entreprise_id[0]}",{entreprise_id})`;
            const linkLieu = `${URLlieu}${queryLieu}${filterByLieu}`;
            return axios({
                method: "get",
                url: linkLieu,
                timeout: 1000 * 5, // Wait for 5 seconds
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer keyOJASKOIpyF1ACT"
                }
            })
            .then((res) => {
                const allLieux = res.data.records;
                console.log(res.data.records);
                //console.log(allLieux);
                setLieuInfo(allLieux);
                props.getLieuEntreprise(allLieux);
                return;

            })
            .catch((error) => console.log(error));
        }
    }

    const getPostalCode = async (placeId) => {
        const [place] = await geocodeByPlaceId(placeId);
        const { long_name: postalCode = '' } =
        place.address_components.find(c => c.types.includes('postal_code')) || {};
        setPostalCode(postalCode);
      };

    useEffect(() => {
        checkLieuEntreprise();
        return;
    }, [userInfo.entreprise_id]);

    // formulaire new lieu
    const handleSubmit = (event) => {
        event.preventDefault();
        
        if(formAddLieu === true){
            const url = "https://hooks.zapier.com/hooks/catch/7206204/32yg4i8/";
            let data = {
                airtableUserId: event.target.airtableid.value,
                entreprise: event.target.entreprise.value,
                entrepriseId:event.target.entrepriseid.value,
                nom: event.target.nom.value,
                adresse: targetAdresse.value.terms[0].value + ' ' + targetAdresse.value.terms[1].value,
                code_postal: postalCode,
                ville: targetAdresse.value.terms[2].value,
            };
            let options = {
                method: "POST",
                headers: { "content-type": "application/x-www-form-urlencoded" },
                data: qs.stringify(data),
                url
            };
    
            axios(options)
                .then(res => {
                    setFormSuccess(true);
                    
                     setTimeout(async () =>  {
                       await checkLieuEntreprise();
                        setFormSuccess(false);
                        setFormAddLieu(false);
                        clearData();
                    }, 3000);
                    ;
                })
                .catch(er => {
                    console.log("erreur ajout", er);
                    setFormError(true);
                    setTimeout(() => {
                        setFormError(false);
                    }, 3000);
                    ;
                });
        }
        else{
            console.log(selectedLieu.fields);
            let url = `https://api.airtable.com/v0/appN12t0eZ3JSYwzx/lieu/${selectedLieu.id}`;
            let data = {
                fields: {
                    entreprise_nom: selectedLieu.entreprise_nom,
                    entreprise: userInfo.entreprise,
                    entreprise_id:[event.target.entrepriseid.value],
                    nom_lieu: event.target.nom.value,
                    adresse: targetAdresse != null ? targetAdresse.value.terms[0].value  + ' ' +  targetAdresse.value.terms[1].value : selectedLieu.fields.adresse,
                    code_postal: postalCode != null && postalCode != '' ? postalCode : selectedLieu.fields.code_postal,
                    ville: targetAdresse != null ? targetAdresse.value.terms[2].value : selectedLieu.fields.ville,
                    audit: selectedLieu.fields.audit,
                }   
            };

            
    
            let options = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer keyOJASKOIpyF1ACT"
                },
                data: JSON.stringify(data),
                url
            };
            axios(options)
                .then(res => {
                    setFormSuccess(true);
                     setTimeout(async () =>  {
                       await checkLieuEntreprise();
                       clearData();
                        setFormSuccess(false);
                        setFormEditLieu(false);

                    }, 3000);
                    ;
                })
                .catch(er => {
                    console.log("erreur ajout", er);
                    setFormError(true);
                    setTimeout(() => {
                        setFormError(false);
                    }, 3000);
                    ;
                });
        }
       

    };

    function clearData(){
        setPostalCode(null);
        setTargetAdresse(null);
        setSelectedLieu(null)
    }

    function deleteLieu(id) {
        return axios({
          method: "delete",
          url: `https://api.airtable.com/v0/appN12t0eZ3JSYwzx/lieu/${id}`,
          headers: {
            Authorization: "Bearer keyOJASKOIpyF1ACT",
            "Content-Type": "application/json"
          }
        })
          .then((response) => {
            setTimeout(() => {
                checkLieuEntreprise();
                toggle()
            }, 1000);
            ;
          })
          .catch((error) => {
            return error;
          });
      }

      if (userInfo.entreprise === "vide") {
        return (
            <Container className="mt-0">
                <Row>
                    <Col md="12">
                        <CardDashboard>
                            <CardBody align="center" className="empty-card">
                                <p>
                                    <span>😔</span> Pour ajouter un lieu vous devez ajouter les informations de votre entreprise.
                                </p>
                            </CardBody>
                        </CardDashboard>
                    </Col>
                </Row>
            </Container>
        )
    }
    // Ajout ou modification d'un lieu
    if (formAddLieu === true || formEditLieu === true) {
        return (
            <Container className="mt-0">
                <Row>
                    <Col md='9' align="left">
                        <h2>{ formAddLieu ? "Ajouter un lieu" : "Modifier un lieu" } </h2>
                    </Col>
                    <Col md='3' align="right">
                        <Button color="light" outline  onClick={() => { setFormAddLieu(false); setFormEditLieu(false)}}>Annuler</Button>
                    </Col>
                    <Col md="12">
                        <CardDashboard>
                            <CardBody>
                                <Form onSubmit={handleSubmit}>
                                {formSuccess === true &&
                                    <>
                                        <Alert color="success">{formAddLieu ? "Lieu crée avec succès" : "Lieu modifié avec succès"} </Alert>
                                    </>
                                }
                                {formError === true &&
                                    <>
                                        <Alert color="danger">Une erreur est survenu</Alert>
                                    </>
                                }
                                    <Input
                                        name="airtableid"
                                        value={member.metaData.airtable_id}
                                        className="d-none"
                                    />
                                    <Input
                                        name="entreprise"
                                        value={userInfo.entreprise}
                                        className="d-none"
                                    />
                                    <Input
                                        name="entrepriseid"
                                        value={userInfo.entreprise_id[0]}
                                        className="d-none"
                                    />

                                    <FormGroup>
                                        <Label for="">
                                            Nom du lieu
                                        </Label>
                                        <Input
                                            name="nom"
                                            placeholder="Siege social, boutique Paris 12e..."
                                            type="text"
                                            defaultValue={formEditLieu ? selectedLieu != null && selectedLieu.fields.nom_lieu : null}
                                            required
                                        />
                                    </FormGroup>
                                    <Label for="">
                                            Adresse du lieu
                                        </Label>
                                    <GooglePlacesAutocomplete
                                        
                                        selectProps={{
                                            onChange: (target) => {
                                                setTargetAdresse(target);
                                                getPostalCode(target.value.place_id);
                                            },
                                            placeholder: selectedLieu != null ? selectedLieu.fields.adresse : "Renseignez votre adresse",
                                            components: {
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null,
                                            },
                                            styles: {
                                            input: (provided) => ({
                                                ...provided,
                                                color: "grey",
                                                height: "38px",
                                                fontSize: "20px",
                                                paddingTop: "2px",
                                                paddingLeft: "8px",
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                // color: "green",
                                                fontSize: "20px",
                                                paddingTop: "0px",
                                                paddingLeft: "10px",
                                            }),
                                            control: (base, state) => ({
                                                ...base,
                                                marginBottom: '10px',
                                                border: "1px solid #ced4da",
                                                borderRadius: "0.25rem",
                                                boxShadow:
                                                state.isFocused &&
                                                "0 0 0 0.2rem rgba(0, 123, 255, 0.25)",
                                                "&:hover": {
                                                border: "1px solid #ced4da",
                                                borderRadius: !state.isFocused && "0.25rem",
                                                marginBottom: '10px',
                                                },
                                            }),
                                            },
                                        }}
                                        />
                                        <Label for="">
                                            Code postal
                                        </Label>
                                        <Input
                                            style={{marginBottom: '10px'}}
                                            name="code_postal"
                                            disabled={true}
                                            placeholder={selectedLieu != null ? selectedLieu.fields.code_postal : "Renseignez votre adresse"}
                                            type="text"
                                            value={postalCode}
                                        />
                                    <Button className="cta btn-block" type="submit">
                                        Valider
                                    </Button>
                                </Form>
                            </CardBody>
                        </CardDashboard>
                    </Col>
                </Row>
            </Container>
        )
    }

    if (lieuInfo !== null & formAddLieu === false) {
        return (
            <Container className="mt-9">
                <Row>
                    <Col md='6' lg='9' align="left">
                    <div className="d-block d-sm-none"><br/><br/></div>
                        <h2>Lieu</h2>
                        <p>Gérez le ou les lieux à auditer dans votre entreprise.</p>
                    </Col>
                    <Col md='6' lg='3' align="right">
                        <a href="#" onClick={() => { setFormAddLieu(true); }} className="btn cta">+ Ajouter un lieu</a>
                    </Col>
                    <Col md="12">
                        <CardDashboard>
                        <Alert color="primary">
                        <FontAwesomeIcon icon={faInfoCircle} /> Répertoriez ici le ou les lieux à expertiser, avant d'ouvrir les accès aux différents utilisateurs.
                        </Alert>
                            <div className="d-flex flex-row justify-content-evenly align-items-center flex-wrap">
                                {lieuInfo.map((col, i) => (
                                    <>
                                        {lieuInfo[i].fields.entreprise === userInfo.entreprise &&
                                            <>
                                                <Card className="m-1 tile"  style={{ minWidth: "300px"}}>
                                                    <CardBody className="p-3">
                                                        <img src={LieuIcon} alt="lieu"/>
                                                    <CardTitle>
                                                        <b>{lieuInfo[i].fields.nom_lieu}</b>
                                                    </CardTitle>
                                                    <CardBody>
                                                        <p>
                                                        {lieuInfo[i].fields.adresse}<br/>
                                                        {lieuInfo[i].fields.code_postal} 
                                                        {lieuInfo[i].fields.ville}
                                                        </p>
                                                    </CardBody>
                                                    <div>
                                                        <Button color="light" outline className="mr-2" onClick={()=>{setFormEditLieu(true); setSelectedLieu(lieuInfo[i])}}> Modifier</Button>
                                                        <Button color="light" outline className="mr-2" onClick={()=>{toggle(); setLieuaSupprimer(lieuInfo[i].id)}}>Supprimer</Button>
                                                    </div>
                                                    </CardBody>
                                                </Card>
                                            </>
                                        }
                                    </>
                                ))}
                            </div>
                        </CardDashboard>
                    </Col>
                </Row>
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Êtes-vous sûre de vouloir supprimer ce lieu ?</ModalHeader>
                    <ModalFooter className="d-flex justify-content-center">
                    <Button color="danger" onClick={()=>{deleteLieu(lieuaSupprimer)}}>
                        Supprimer
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        Annuler
                    </Button>
                    </ModalFooter>
                </Modal>
            </Container>
            
        )
    }

    return (
        <></>
    )
}

export default Lieu;
