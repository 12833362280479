import axios from "axios";
import { urlAxiosQuestion, urlAxiosAudit, urlAxiosCategorieQuestion, urlAxiosReponse } from './function';

// GET INFO AUDIT FUNTCTIONS
export function getInfoAudit(id) {
  const idAudit = id;
  const URL = 'https://api.airtable.com/v0/appN12t0eZ3JSYwzx/audit';
  const query = '?filterByFormula=';
  const filterBy = `SEARCH("${idAudit}",{airtable_id})`;
  const link = `${URL}${query}${filterBy}`;

  return axios({
    method: "get",
    url: link,
    timeout: 1000 * 5,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer keyOJASKOIpyF1ACT"
    }
  })
    .then((records) => {
      //console.log("info audit function", records.data.records[0]);
      const infoAudit = records.data.records[0];
      return infoAudit;
    })
    .catch((error) => console.log(error));
  ;
}

// GET question FUNTCTIONS
export function getQuestion(typeQuestionnaire) {
  const URL = 'https://api.airtable.com/v0/appN12t0eZ3JSYwzx/questions';
  const query = '?filterByFormula=';
  const filterBy = `SEARCH("${typeQuestionnaire}",{nom_questionnaire})`;
  const link = `${URL}${query}${filterBy}`;

  return axios({
    method: "get",
    url: link,
    timeout: 1000 * 5,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer keyOJASKOIpyF1ACT"
    }
  })
    .then((records) => {
      //console.log("liste des questions", records.data.records);
      const listeQuestions = records.data.records;
      return listeQuestions;
    })
    .catch((error) => console.log(error));
}

// GET QUESTIONS FUNTCTIONS
export function getQuestionInfo() {
  return fetch(urlAxiosQuestion)
    .then((res) => res.json())
    .then((res) => {
      //console.log("allquestions function", res)
      const allquestions = res.records;
      return allquestions;
    })
    .catch((error) => console.log(error));
}

// GET CATEGORIES QUESTIONS FUNTCTIONS
export function getCategorieQuestion() {
  return fetch(urlAxiosCategorieQuestion)
    .then((res) => res.json())
    .then((res) => {
      //console.log("all category function", res)
      const allcategories = res.records;
      allcategories.sort(function (a, b) {
        return a.fields.ordre - b.fields.ordre;
      });
      return allcategories;
    })
    .catch((error) => console.log(error));
}

// GET REPONSE
export function getReponse(idAudit) {
  const URL = 'https://api.airtable.com/v0/appN12t0eZ3JSYwzx/reponses';
  const query = '?filterByFormula=';
  const filterBy = `SEARCH("${idAudit}",{audit_id})`;
  const link = `${URL}${query}${filterBy}`;

  return axios({
    method: "get",
    url: link,
    timeout: 1000 * 5,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer keyOJASKOIpyF1ACT"
    }
  })
    .then((records) => {
      //console.log("reponse", records.data.records);
      return records;
    })
    .catch((error) => console.log(error));
}

// CHECK RESULTAT AUDIT
export function checkResult(idAudit) {
  //console.log("idAudit",idAudit);
  const URL = 'https://api.airtable.com/v0/appN12t0eZ3JSYwzx/resultat_audit';
  const query = '?filterByFormula=';
  const filterBy = `SEARCH("${idAudit}",{audit_id})`;
  const link = `${URL}${query}${filterBy}`;

  return axios({
    method: "get",
    url: link,
    timeout: 1000 * 5,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer keyOJASKOIpyF1ACT"
    }
  })
    .then((records) => {
      //console.log("auditReponseExistant", records);
      const reponse = records;
      return reponse;
    })
    .catch((error) => console.log(error));
}

// GÉNÉRER LES RÉSULTATS
export function genererResultat(idAudit) {
  console.log("idAudit", idAudit);
  //on récupère toutes les réponses de l'audit
  const URL = 'https://api.airtable.com/v0/appN12t0eZ3JSYwzx/reponses';
  const query = '?filterByFormula=';
  const filterBy = `SEARCH("${idAudit}",{audit_id})`;
  const link = `${URL}${query}${filterBy}`;

  return axios({
    method: "get",
    url: link,
    timeout: 1000 * 5,
    headers: {
      "Content-Type": "application/json", Authorization: "Bearer keyOJASKOIpyF1ACT"
    }
  })
    .then((records) => { console.log("reponse", records.data.records); let reponses = records.data.records;})
    .catch((error) => console.log(error));

    console.log("reponses", reponses);
}

