import styled from "styled-components";

export const MainContainer = styled.div`
  width: 20rem;
  min-height: 40rem;
  height: max-content;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  color: #1d3557;
  box-shadow: 0 2px 9px -6px ${(props) => props.shadow};
  transition: 0.4s ease-in-out;
  margin-right:1rem;
  &:hover {
    box-shadow: 0 8px 26px -6px ${(props) => props.shadow};
    margin-bottom: 0.4rem;
  }
`;

export const Header = styled.div`
  margin: 0.6rem;
  height: 4rem;
  background-color: #fae4fb;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
  font-weight: 600;
`;

export const PricingContainer = styled.div`
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
`;

export const PriceContainer = styled.div`
  display: flex;
`;

export const CurrencyContainer = styled.div`
  margin-top: 0.5rem;
  margin-right: 0.2rem;
`;

export const Price = styled.div`
  span {
    font-size: 3rem;
    font-weight:bold;
  }
`;

export const Duration = styled.div`
  margin-top: 2rem;
`;

export const SubTitle = styled.div`
  text-transform: uppercase;
  text-align: center;
  margin: 0.4rem 0 1.3rem 0;
`;

export const PriceText = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width:90%;
`;

export const DataContainer = styled.div`
  ul {
    margin-top:2rem;
    list-style-type: none;
    li {
      display: flex;
      align-items: center;
      .true {
        color: #34f034;
        font-size: 1rem;
      }
      .false {
        color: #f54343;
      }
      svg {
        margin-right: 0.5rem;
        font-size: 0.8rem;
      }
      margin-bottom: 1rem;
    }
  }
`;