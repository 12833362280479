export const urlAxiosQuestion =
  "https://api.airtable.com/v0/appN12t0eZ3JSYwzx/questions?api_key=keyOJASKOIpyF1ACT";

  export const urlAxiosUser =
  "https://api.airtable.com/v0/appN12t0eZ3JSYwzx/user?api_key=keyOJASKOIpyF1ACT";

  export const urlAxiosAudit =
  "https://api.airtable.com/v0/appN12t0eZ3JSYwzx/audit?api_key=keyOJASKOIpyF1ACT";

  export const urlAxiosCategorieQuestion =
  "https://api.airtable.com/v0/appN12t0eZ3JSYwzx/categorie_question?api_key=keyOJASKOIpyF1ACT";

  export const urlAxiosReponse =
  "https://api.airtable.com/v0/appN12t0eZ3JSYwzx/reponses?api_key=keyOJASKOIpyF1ACT";


  export const autre =
  "https://api.airtable.com/v0/appN12t0eZ3JSYwzx/user?memberstack_id=${member.id}";

  