import styled from "styled-components";
import { NavbarBrand,Nav,Navbar } from "reactstrap";
import { Link } from "react-router-dom";

export const NavbarBrandS = styled(NavbarBrand)`
  align-self: center;
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: -1px;
  img {
    margin-right: 3px;
  }
`;

export const NavS = styled(Nav)`
  display: flex;
  flex-direction: row;
  li.nav-item {
    margin-right: 15px;
  }
  a {
    color: #111111 !important;
    align-self: center;
    font-weight: 600;
  }
`;
export const NavLinkButton = styled(Link)`
    padding: 8px 16px!important;
    border-radius: 8px;
    box-shadow: inset 0 0 0 2px rgb(0 0 0 / 20%);
    transition: box-shadow 200ms ease-in-out;
    a{padding:0!important;}
}
`;

export const NavbarS = styled(Navbar)`
  background-color: #fff;
  overflow: hidden;
  background-image: url("https://finfrog.fr/static-images/landing/bg-card-tabs.svg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 10%;

  a.navbar-brand{
    align-self: center;
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: -1px;
  color:black;
  img {
    margin-right: 3px;
  }
  }
}
`;

export const NavS2 = styled(Navbar)`
  background-color: #E6F9F8;
  overflow: hidden;
  background-image: url("https://finfrog.fr/static-images/landing/bg-card-tabs.svg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 10%;

  a.navbar-brand{
    align-self: center;
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: -1px;
  color:black;
  img {
    margin-right: 3px;
  }
  }
}
`;

export const SectionTabs = styled.section`
  background-color:white;
  color:black;
  .nav-tabs {
    border-bottom: 0;
    .nav-link,a {
      color: #14171B;
      margin-bottom: -1px;
      border: 0;
      border-bottom: 5px solid #fff;
      opacity:50%;
      
      &.active{
        color: #14171B;
        border: 0;
        border-bottom: 5px solid #fade6e;
        opacity:100%;
        h5{
          font-weight:600;
        }
      }
      h5{
        font-weight:400;
      }
    }
  }
`;
