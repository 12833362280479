import styled from "styled-components";
import { Col,Nav, } from "reactstrap";
import ProgressBar from "@ramonak/react-progress-bar";

export const ContainerQuestion = styled.div`
  position: relative;
  max-width: 90%;
  margin-top:5%!important;
  margin: 0 auto;
  border-radius: 1rem;
  &.button-bottom {
    background: transparent !important;
  }
`;
export const ColCategorie = styled(Col)`
  background-color:white;
  padding: 2%;
  
  @media only screen and (max-width: 768px) {
    min-heigth:auto!important;
    padding:0 6%;
    a.active{
        background-color:#fade6e!important;
        color:black!important;
    }
  }
`;
export const TabsCategorie = styled(Nav)`
  border:0;
  margin-top:1rem;
  .nav-item{
    border-bottom:1px solid #F5F5F5;
    a.nav-link{
        opacity:50%;
        display:flex;
        justify-content: space-between;
        svg{opacity:0}
        &.active{
            color: #14171B;
        border: 0;
        border-bottom: 5px solid #fade6e;
        opacity:100%;
        svg{opacity:100%}
        }
        &:hover{cursor:pointer;}
    }
  }
  @media only screen and (max-width: 768px) {
    display:none;
}
  
`;

export const NavCategorieMobile = styled(Nav)`
@media only screen and (min-width: 769px) {
    display:none;
}
  @media only screen and (max-width: 768px) {
    width:100%;
    overflow:auto;
    display:flex;
    flex-direction:row;
    .nav{
      flex-wrap:nowrap!important;
      overflow:scroll;
    }
  h5{
    font-size:1rem;
  }
  li{
    min-width:30%;
    svg{display:none;}
    p{margin-bottom:0;}
  }
  
  }
`;


export const ColQuestion = styled(Col)`
  
`;
export const AnswerOptions = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display:flex;
  justify-content:center;
  border-top: 1px solid #eee;
`;
export const AnswerLabel = styled.li`
  padding: 1%;
  font-size: 16px;
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  display:flex;
  width:100%;

  button{
    padding: 1rem;
    margin: 0 auto;
    width:100%;
  }
  .btn-outline-primary{
    color: #3e817b!important;
    border-color: #ddd!important;
    &:hover,&:focus{
        background-color:#3e817b!important;
        color:white!important;
    }
    
  }
  .btn-outline-danger{
    border-color: #ddd!important;
    &:hover,&:visited{
        color:white!important;
    }

  }

 
`;

export const Question = styled.div`
background-color: white;
margin-bottom: 2rem;
border-radius: 1rem;
.bloc-modif{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  padding: 1rem 1rem;
  p{margin-bottom:0;opacity:.5;}
  a &:hover{cursor:pointer!important;}
}

`;
export const BlocPrecisionNon = styled.div`
padding:2% 11%;
`;

export const ProgressBarS = styled(ProgressBar)`
span{
  background-color:#3D817B!important;
}
`;

export const BarreProgression = styled.div`
p{
  font-size: 75%;
    font-weight: 700;
    margin-bottom: 0.5rem;
    padding:3%;
}
`;





