import styled from "styled-components";

export const TitleH1 = styled.h1`
margin-top: 20px;
margin-bottom: 16px;
font-size: 48px;
line-height: 62px;
font-weight: 500;
letter-spacing: -1px;
`;

export const Subtitle = styled.p`
font-size: 22px;
    line-height: 36px;
`;


