import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { userInfoContext } from "../App";
import classnames from 'classnames';
import qs from "qs";
import { Container, Row, Col, CardBody, Form, FormGroup, Label, Button, Input, Alert, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import styled from "styled-components";
import { CardDashboard } from "../styles/Card";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId } from 'react-places-autocomplete';

export const NavTabSmall = styled(Nav)`
 .nav-link{
    border:0;
    font-weight: 400!important;
    opacity:.3!important;
    span{display:none;}
    &:hover{
        cursor:pointer;
        opacity:1;
    }
    &.active{
        font-weight: 800!important;
        opacity:1!important;
        color:black;
        span{display:contents;}
    }
}


`;

function Entreprise(props) {
    const member = props.member;
    //const userInfo = props.userInfo;
    const { userInfo, setUserInfo } = useContext(userInfoContext);
    const [stopRequest, setStopRequest] = useState(false);
    const [entrepriseInfo, setEntrepriseInfo] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [formError, setFormError] = useState(false);
    const [formSuccess2, setFormSuccess2] = useState(false);
    const [formError2, setFormError2] = useState(false);
    const [targetAdresse, setTargetAdresse] = useState(null);
    const [postalCode, setPostalCode] = useState("");


    var Airtable = require('airtable');
    var base = new Airtable({ apiKey: 'keyOJASKOIpyF1ACT' }).base('appN12t0eZ3JSYwzx');

    /* on charge les info entreprise */
    function checkInfoEntreprise() {
        return base('entreprise').find(userInfo.entreprise_id, function (err, record) {
            if (err) { console.error(err); return; }
            if (record) { 
                let entrepriseToSave = record.fields; 
                entrepriseToSave["id"] = record.id; 
                setEntrepriseInfo(entrepriseToSave); 
                setStopRequest(true); 
                return; 
            }
        });
    }

    useEffect(() => {
        if (userInfo.entreprise_id) {
            checkInfoEntreprise();
            return;
        }
    }, []);

    const getPostalCode = async (placeId) => {
        const [place] = await geocodeByPlaceId(placeId);
        const { long_name: postalCode = '' } =
        place.address_components.find(c => c.types.includes('postal_code')) || {};
        setPostalCode(postalCode);
      };


    // formulaire entreprise
    const handleSubmit = (event) => {
        event.preventDefault();

        let url = "";
        let data = {};
        let options = {};

        if(userInfo.entreprise === "vide" ){
            url = `https://api.airtable.com/v0/appN12t0eZ3JSYwzx/entreprise`;
            data = {
                "records" : [
                    { 
                        "fields": 
                        {
                            "entreprise": event.target.nom.value,
                            "siret": event.target.siret.value,
                            "adresse": targetAdresse.value.terms[0].value + ' ' + targetAdresse.value.terms[1].value,
                            "code_postal": postalCode,
                            "ville": targetAdresse.value.terms[2].value,
                            "user": [event.target.airtableid.value],
                        }
                    }
                ]   
             };
             options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + "keyOJASKOIpyF1ACT"
                  },
                data: data,
                url
            };
        }else{
            url = `https://api.airtable.com/v0/appN12t0eZ3JSYwzx/entreprise/${entrepriseInfo.id}`;
            data = {
                    "fields": 
                        {
                            "siret": event.target.siret.value,
                            "adresse": targetAdresse !== undefined &&  targetAdresse !== null ?  targetAdresse.value.terms[0].value + ' ' + targetAdresse.value.terms[1].value : entrepriseInfo.adresse,
                            "code_postal": postalCode !== "" ? postalCode : entrepriseInfo.code_postal,
                            "ville":  targetAdresse !== undefined &&  targetAdresse !== null ? targetAdresse.value.terms[2].value : entrepriseInfo.ville,
                        }
             };
             options = {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + "keyOJASKOIpyF1ACT"
                  },
                data: data,
                url
            };
        }

        axios(options)
            .then(async res => {
                setFormSuccess(true);
                setFormSuccess2(true);
                setTimeout(() => {
                    setFormSuccess(false);
                    setFormSuccess2(false);
                }, 2000);

                await axios(
                    {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + "keyOJASKOIpyF1ACT"
                        },
                        data: {
                            "fields": {
                                "entreprise": userInfo.entreprise === "vide" ? res.data.records[0].fields.entreprise : res.data.fields.entreprise
                            }
                        },
                        url: `https://api.airtable.com/v0/appN12t0eZ3JSYwzx/user/${event.target.airtableid.value}`,
                    }
                )

               
                var newEntrepriseInfo = userInfo.entreprise === "vide" ? res.data.records[0].fields :  res.data.fields;
                newEntrepriseInfo.id = userInfo.entreprise === "vide" ? res.data.records[0].id :  res.data.id;
                setEntrepriseInfo(newEntrepriseInfo);
                
                setTimeout(() => {  
                    axios({
                        method: "get",
                        url: `https://api.airtable.com/v0/appN12t0eZ3JSYwzx/entreprise?filterByFormula=SEARCH("${event.target.airtableid.value}",{user})`,
                        timeout: 1000 * 5, // Wait for 5 seconds
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + "keyOJASKOIpyF1ACT"
                        },
                      }).then((resp) => {
                        userInfo["entreprise_id"] = [userInfo.entreprise === "vide" ? resp.data.records[0].id : resp.data.id];
                        userInfo["entreprise"] = event.target.nom.value;
                        setUserInfo(userInfo);
                      })
                }, 100)
               
                
                
            })
            .catch(er => {
                console.log(er);
                setFormError(true);
                setFormError2(true);
                setTimeout(() => {
                    setFormError(false);
                    setFormError2(false);
                }, 3000);
            });

    };

    if (userInfo.entreprise === "vide" ) {
        return (
            <Container className="mt-0">
                <Row>
                    <Col md="12">
                        <CardDashboard>
                            <CardBody>
                                <div className="d-flex justify-content-left">
                                    <h2>Ajoutez votre entreprise</h2>
                                </div>
                                {formSuccess === true &&
                                    <>
                                        <Alert color="success">Informations ajoutées.</Alert>
                                    </>
                                }
                                {formError === true &&
                                    <>
                                        <Alert color="danger">Une erreur est survenu, veuillez recommencer.</Alert>
                                    </>
                                }
                                <Form onSubmit={handleSubmit}>
                                    <Input
                                        name="airtableid"
                                        value={member.metaData.airtable_id}
                                        className="d-none"
                                    />

                                    <FormGroup>
                                        <Label for="">
                                            Nom entreprise
                                        </Label>
                                        <Input
                                            name="nom"
                                            placeholder=""
                                            type="text"
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="">
                                            SIRET
                                        </Label>
                                        <Input
                                            name="siret"
                                            placeholder=""
                                            type="text"
                                        />
                                    </FormGroup>
                                    <Label for="">
                                            Adresse
                                        </Label>
                                    <GooglePlacesAutocomplete
                                        selectProps={{
                                            onChange: (target) => {
                                                setTargetAdresse(target);
                                                getPostalCode(target.value.place_id);
                                            },
                                            placeholder: entrepriseInfo != null ? entrepriseInfo.adresse : "Renseignez votre adresse",
                                            components: {
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null,
                                            },
                                            styles: {
                                            input: (provided) => ({
                                                ...provided,
                                                color: "grey",
                                                height: "38px",
                                                fontSize: "20px",
                                                paddingTop: "2px",
                                                paddingLeft: "8px",
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                // color: "green",
                                                fontSize: "20px",
                                                paddingTop: "0px",
                                                paddingLeft: "10px",
                                            }),
                                            control: (base, state) => ({
                                                ...base,
                                                marginBottom: '10px',
                                                border: "1px solid #ced4da",
                                                borderRadius: "0.25rem",
                                                boxShadow:
                                                state.isFocused &&
                                                "0 0 0 0.2rem rgba(0, 123, 255, 0.25)",
                                                "&:hover": {
                                                border: "1px solid #ced4da",
                                                borderRadius: !state.isFocused && "0.25rem",
                                                marginBottom: '10px',
                                                },
                                            }),
                                            },
                                        }}
                                        />
                                        <Label for="">
                                            Code postal
                                        </Label>
                                        <Input
                                            style={{marginBottom: '10px'}}
                                            name="code_postal"
                                            disabled={true}
                                            placeholder={entrepriseInfo != null && entrepriseInfo.code_postal}
                                            type="text"
                                            value={postalCode}
                                        />
                                    <Button className="cta btn-block" type="submit">
                                        Valider
                                    </Button>
                                </Form>
                            </CardBody>
                        </CardDashboard>
                    </Col>
                </Row>
            </Container>
        )
    }
    if (userInfo.entreprise !== "vide") {
        return (
            <Container className="mt-0">
                <Row>
                    <Col md="12" align="left">
                    <div className="d-block d-sm-none"><br/><br/></div>
                        <h2>Entreprise</h2>
                        <p>Gérer les informations de votre entreprise.</p>
                    </Col>
                    <Col md="12">
                        <CardDashboard>
                            <CardBody>
                               
                                <Form onSubmit={handleSubmit}>
                                {formSuccess2 === true &&
                                    <>
                                        <Alert color="success">Informations mis à jour.</Alert>
                                    </>
                                }
                                {formError2 === true &&
                                    <>
                                        <Alert color="danger">Une erreur est survenu, veuillez recommencer.</Alert>
                                    </>
                                }
                                    <Input
                                        name="airtableid"
                                        defaultValue={member.metaData.airtable_id}
                                        className="d-none"
                                    />

                                    <FormGroup>
                                        <Label for="">
                                            Nom entreprise
                                        </Label>
                                        <Input
                                            name="nom"
                                            defaultValue={userInfo.entreprise}
                                            type="text"
                                            required
                                            disabled
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="">
                                            SIRET
                                        </Label>
                                        <Input
                                            name="siret"
                                            defaultValue={entrepriseInfo.siret}
                                            type="text"
                                        />
                                    </FormGroup>
                                    <Label for="">
                                            Adresse
                                        </Label>
                                    <GooglePlacesAutocomplete
                                        selectProps={{
                                            onChange: (target) => {
                                                setTargetAdresse(target);
                                                getPostalCode(target.value.place_id);
                                            },
                                            placeholder: entrepriseInfo != null ? entrepriseInfo.adresse : "Renseignez votre adresse",
                                            components: {
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null,
                                            },
                                            styles: {
                                            input: (provided) => ({
                                                ...provided,
                                                color: "grey",
                                                height: "38px",
                                                fontSize: "20px",
                                                paddingTop: "2px",
                                                paddingLeft: "8px",
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                // color: "green",
                                                fontSize: "20px",
                                                paddingTop: "0px",
                                                paddingLeft: "10px",
                                            }),
                                            control: (base, state) => ({
                                                ...base,
                                                marginBottom: '10px',
                                                border: "1px solid #ced4da",
                                                borderRadius: "0.25rem",
                                                boxShadow:
                                                state.isFocused &&
                                                "0 0 0 0.2rem rgba(0, 123, 255, 0.25)",
                                                "&:hover": {
                                                border: "1px solid #ced4da",
                                                borderRadius: !state.isFocused && "0.25rem",
                                                marginBottom: '10px',
                                                },
                                            }),
                                            },
                                        }}
                                        />
                                        <Label for="">
                                            Code postal
                                        </Label>
                                        <Input
                                            style={{marginBottom: '10px'}}
                                            name="code_postal"
                                            disabled={true}
                                            placeholder={entrepriseInfo != null && entrepriseInfo.code_postal}
                                            type="text"
                                            value={postalCode}
                                        />
                                    <Button className="cta btn-block" type="submit">
                                        Mettre à jour
                                    </Button>
                                </Form>
                            </CardBody>
                        </CardDashboard>
                    </Col>
                </Row>
            </Container>
        )
    }
    return (
        <></>
    )
}

export default Entreprise;
