import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom"
import { useMemberstack } from "@memberstack/react";
import axios from "axios";
import { userInfoContext } from "./App";
import Dash from "./Components/Dash";
import Parametres from "./Components/Parametres";
import Entreprise from "./Components/Entreprise";
import Utilisateurs from "./Components/Utilisateurs";
import Audit from "./Components/Audit";
import Lieu from "./Components/Lieu";
import Loading from "./Components/Loading";

/** style  **/
import { NavbarBrandS, NavbarS, SectionTabs } from "./styles/Navbar";
import {
  Container,
  Nav,
  Collapse,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink, TabContent, TabPane
} from "reactstrap";

const Dashboard = () => {
  const memberstack = useMemberstack();
  const [lieuEntreprise, setLieuEntreprise] = useState(null);
  const [member, setMember] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  //const [userInfo, setUserInfo] = useState(null);
  const { userInfo, setUserInfo } = useContext(userInfoContext);
  const [stopRequest, setStopRequest] = useState(false);
  const [entrepriseInfo, setentrepriseInfo] = useState(null);

  if (member !== null & stopRequest === false) {
    //console.log("fonction airtable");
    axios({
      method: "get",
      url: `https://api.airtable.com/v0/appN12t0eZ3JSYwzx/user/${member.metaData.airtable_id}`,
      headers: {
        Authorization: "Bearer keyOJASKOIpyF1ACT",
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        console.log(response.data.fields);
        //console.log("response",response.data.fields);
        setUserInfo(response.data.fields);
        setStopRequest(true);
        return;
      })
      .catch((error) => {
        return error;
      });
  };

  useEffect(() => {
    if (member === null || userInfo === null) {
      memberstack.getCurrentMember()
        .then(({ data: member }) => setMember(member))
    }
  }, [member]);


  if (member !== null & userInfo !== null) {
    return (
      <>
        <header>
          <NavbarS light expand="md">
            <NavbarBrandS href="/">
              <img width="50" src="https://assets.website-files.com/63c92fdaaa2630f6c55d3f41/63c936dbf818bd38a251413f_LogoAiTool.svg" alt="logo" className="mb-3" />
            </NavbarBrandS>
            <NavbarToggler />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="mr-auto" navbar>
                <NavItem>
                  <NavLink href="/"></NavLink>
                </NavItem>
              </Nav>
              {member && (
                <>
                  <a className="nav-link"
                    href="#"
                    onClick={() =>
                      memberstack.logout()
                        .then(({ data, type }) => {
                          window.location.replace('/');
                        })}
                  >
                    Deconnexion
                  </a>
                  <a
                    outline
                    className="mr-2 cta"
                    type="button"
                    href="/admin"
                  >
                    {member.auth.email}
                  </a>
                </>
              )}


            </Collapse>
          </NavbarS>
          <SectionTabs>
            <Nav tabs className='justify-content-center flex-row'>
              <NavItem>
                <Link
                      to="/dashboard"
                      className={`nav-link  ${window.location.pathname === "/dashboard" ? "active" : ""} ${window.location.pathname === "/" ? "active" : ""}`}
                    >
                      <h5>Dashboard</h5>
                    </Link>

              </NavItem>
              <NavItem>
                {userInfo.role === "admin" &&
                  <>
                    <Link
                      to="/entreprise"
                      className={`nav-link  ${window.location.pathname === "/entreprise" ? "active" : ""}`}
                    >
                      <h5>Entreprise</h5>
                    </Link>
                  </>
                }
              </NavItem>
              <NavItem>
                {userInfo.role === "admin" &&
                  <>
                    <Link
                      to="/lieu"
                      className={`nav-link  ${window.location.pathname === "/lieu" ? "active" : ""}`}
                    >
                      <h5>Lieu</h5>
                    </Link>
                  </>
                }
              </NavItem>

              <NavItem>
                {userInfo.role === "admin" &&
                  <>
                  <Link
                      to="/utilisateurs"
                      className={`nav-link  ${window.location.pathname === "/utilisateurs" ? "active" : ""}`}
                    >
                      <h5>Utilisateurs</h5>
                    </Link>
                  </>
                }
              </NavItem>
              <NavItem>
              <Link
                      to="/audit"
                      className={`nav-link  ${window.location.pathname === "/audit" ? "active" : ""}`}
                    >
                      <h5>Audit</h5>
                    </Link>
              </NavItem>
              <NavItem>
                {userInfo.role === "admin" &&
                  <>
                  <Link
                      to="/parametres"
                      className={`nav-link  ${window.location.pathname === "/parametres" ? "active" : ""}`}
                    >
                      <h5>Facturation</h5>
                    </Link>
                  </>
                }
              </NavItem>
            </Nav>
          </SectionTabs>
        </header>

        <Container>
          <TabContent>
            <TabPane tabId="dashboard" className={`${window.location.pathname === "/dashboard" ? "active" : ""} ${window.location.pathname === "/" ? "active" : ""}`}>

              <Dash member={member} userInfo={userInfo} />

            </TabPane>

            <TabPane tabId="entreprise" className={`${window.location.pathname === "/entreprise" ? "active" : ""}`}>
              {userInfo &&
                <Entreprise member={member} userInfo={userInfo} getEntrepriseInfo={(entreprise) =>{setentrepriseInfo(entreprise)}} />
              }
            </TabPane>
            <TabPane tabId="lieu" className={`${window.location.pathname === "/lieu" ? "active" : ""}`}>
              {userInfo &&
                <Lieu member={member} userInfo={userInfo} getLieuEntreprise={(lieuEntreprise)=> setLieuEntreprise(lieuEntreprise)} entreprise={entrepriseInfo}/>
              }

            </TabPane>
            <TabPane tabId="utilisateurs" className={`${window.location.pathname === "/utilisateurs" ? "active" : ""}`}>
              {userInfo &&
                <Utilisateurs member={member} userInfo={userInfo} lieuEntreprise={lieuEntreprise}/>
              }
            </TabPane>
            <TabPane tabId="audit" className={`${window.location.pathname === "/audit" ? "active" : ""}`}>
              {userInfo &&
                <Audit member={member} userInfo={userInfo} />
              }
            </TabPane>
            <TabPane tabId="parametres" className={`${window.location.pathname === "/parametres" ? "active" : ""}`}>
            {userInfo &&
                <Parametres member={member} userInfo={userInfo} />
              }
              
            </TabPane>
          </TabContent>

        </Container>

      </>
    )
  }

  if (member !== null & userInfo === null) {
    return <Loading/>
  }

  return <><Loading/></>

};

export default Dashboard;
