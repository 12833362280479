import React, { useEffect, useState } from "react";
import axios from "axios";
import { useMemberstack } from "@memberstack/react";
import { useParams } from "react-router";
import { Link } from "react-router-dom"
import classnames from 'classnames';
import qs from "qs";
import {
    Container, Badge, Button, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Navbar, Collapse, NavbarToggler, FormGroup, Label, Input, Form, Progress, Alert, Modal,
} from "reactstrap";
import { getInfoAudit, getQuestion, getCategorieQuestion,genererResultat } from './Functions/function-questions';
import Loading from "./Components/Loading";
import CardHelp from "./Components/CardHelp";
/** style  **/
import { NavbarBrandS, NavbarS, SectionTabs } from "./styles/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { ContainerQuestion, ColCategorie, TabsCategorie, NavCategorieMobile, ColQuestion, AnswerOptions, AnswerLabel, Question, BlocPrecisionNon, BarreProgression } from "./styles/Questions"
import {ButtonPrimary} from "./styles/Buttons" 
import { PickerOverlay } from 'filestack-react';

const Questionnaire = () => {
    const memberstack = useMemberstack();
    const [member, setMember] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [auditInfo, setAuditInfo] = useState(null);
    const [categories, setCategories] = useState(null);
    const [afficherCategorie, setAfficherCategorie] = useState(null);
    const [reponse, setReponse] = useState([]);
    const [reponsesQuestionID, setReponsesQuestionID] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [formError, setFormError] = useState(false);
    const [currentActiveTab, setCurrentActiveTab] = useState('1');
    const { id } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [percent, setPercent] = useState(0);
    const [percentsArray, setPercentsArray] = useState([]);
    const [showPickerOverlay, setShowPickerOverlay] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);
    const [urlModal, setUrlModal] = useState('');



    const apikey = 'ARhFaNF3JR2uYCojepJ6Iz';
    const onSuccess = (res, question) => {

        if(question.reponse != null || question.reponse != undefined){
            question.reponse.fields.media = [];

            for (let i = 0; i < res.filesUploaded.length; i++) {
                let element = res.filesUploaded[i];
                console.log(element);
    
                question.reponse.fields.media.push({
                    filename: element.filename,
                    url: element.url,
                    type: element.mimetype,
                })
            }
        }else{

            let media = [];
            for (let i = 0; i < res.filesUploaded.length; i++) {
                let element = res.filesUploaded[i];
    
                media.push({
                    filename: element.filename,
                    url: element.url,
                    type: element.mimetype,
                })
            }

            question.reponse = {
                fields: {
                    media: media,
                }
            }
        }
        
        setShowPickerOverlay(false);
        setRefresh(!refresh);
    };

    const handleUpload = async (question) => {


        if (question?.reponse?.fields?.media !== null && question?.reponse?.fields?.media !== undefined && question?.reponse?.fields?.media.length > 0) {

            const urlReponse = 'https://api.airtable.com/v0/appN12t0eZ3JSYwzx/reponses';
            const headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer keyOJASKOIpyF1ACT"
            }


            const data = {
                "records": [
                    {
                        "fields": {
                            "audit_id": [id],
                            "question_id": [question.id],
                            "reponse": "non",
                            "commentaire": question.fields.commentaire,
                        }
                    }
                ]
            };

            if (question?.reponse?.fields?.media != null) {
                for (let i = 0; i < question.reponse.fields.media.length; i++) {
                    const element = question.reponse.fields.media[i] 
                    question.reponse.fields.media[i] = {
                        filename: element.filename,
                        url: element.url,
                    }
                }
                data.records[0].fields.media = question?.reponse?.fields?.media;
            }

            if (reponsesQuestionID.includes(question.id)) {
                let recIDReponse = reponse.find(value => value.fields.question_id[0] === question.id)
                await axios({
                    method: "PUT",
                    url: urlReponse + "/" + recIDReponse.id,
                    headers: headers,
                    data: data.records[0]
                });


            } else {
                await axios({
                    method: "POST",
                    url: urlReponse,
                    headers: headers,
                    data: data
                });
            }



            question.isAnswered = true;
            handlePercent(true);

            setRefresh(!refresh)

        } else {
            question.isError = true;
            setRefresh(!refresh)
        }
    };

    function editAuditStatus(percentData) {
        const urlAudit = `https://api.airtable.com/v0/appN12t0eZ3JSYwzx/audit/${auditInfo.id}`;
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer keyOJASKOIpyF1ACT"
        }

        let data = {
            fields: {
                "avancement": ""
            }
        };


        if (percentData != 100 && percentData != 0) {
            // EN cours
            data.fields["avancement"] = "En cours"

        } else if (percentData == 100) {
            // Terminé
            data.fields["avancement"] = "Terminé"
        } else {
            // Pas commencé
            data.fields["avancement"] = "Pas commencé"
        }

        axios.patch(urlAudit, data, { headers })
            .then(response => {
                console.log('Field updated successfully:', response.data.fields[fieldName]);
            })
            .catch(error => {
                console.error('Error updating field:', error);
            });

    }

    
    function refreshPage() {
        setTimeout(()=>{
            window.location.reload(false);
        }, 500);
        console.log('page to reload')
    }
    // tab active
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }

    useEffect(() => {
        setPercent(percent);
    }, [percent])

    //chargement session user + récupération data de l'audit
    useEffect(async () => {
        //récupération info user
        memberstack.getCurrentMember()
            .then(({ data: member }) => setMember(member))

        //récupération des data de l'audit
        getInfoAudit(id)
            .then(async (res) => {
                setAuditInfo(res);
                //chargement des bonnes questions
                if (res !== null & loading1 === false) {
                    //console.log("audit info",auditInfo.fields.type_nom[0]);
                    getQuestion(res.fields.type_nom[0])
                        .then(async (res) => {
                            setQuestions(res);
                            //console.log("liste question", res);
                            setLoading(true);
                        })
                        .catch((error) => console.log(error));
                }

                //chargement des catégories des questions et des réponses déjà faite
                if (loading1 === false) {
                    getCategorieQuestion()
                        .then(async (res) => {
                            setCategories(res);
                            //console.log("categorie", res);
                            setLoading1(true);
                            setAfficherCategorie(res[0])
                            return;
                        })
                        .catch((error) => console.log(error));

                }
                setLoading1(true);
                return;
            })
            .catch((error) => console.log(error));

        // GET response 

        const urlReponse = 'https://api.airtable.com/v0/appN12t0eZ3JSYwzx/reponses';
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer keyOJASKOIpyF1ACT"
        }

        const filterByAudit = `?filterByFormula=SEARCH("${id}",{audit_id})`;
        const fullURL = urlReponse + filterByAudit

        let res = await axios({
            method: "GET",
            url: fullURL,
            headers: headers
        });
        setReponse(res.data.records);
    }, []);

    useEffect(() => {
        setReponse(reponse);

        setRefresh(!refresh);

        if (reponse !== null && reponse !== undefined) {
            for (let i = 0; i < reponse.length; i++) {
                setReponsesQuestionID(reponsesQuestionID => [...reponsesQuestionID, reponse[i].fields.question_id[0]]);
            }
        }

    }, [reponse])

    useEffect(() => {
        setReponsesQuestionID(reponsesQuestionID);

        for (let i = 0; i < questions.length; i++) {
            if (reponsesQuestionID.includes(questions[i].id)) {
                questions[i].isAnswered = true;

                let goodAnswer = reponse.find(value => value.fields.question_id[0] === questions[i].id)

                if(goodAnswer === null || goodAnswer === undefined){
                    questions[i].reponse = {

                    }
                }else{
                    questions[i].reponse = goodAnswer;
                }

                

                

                // TODO - Faire en sorte que la reponse soit oui ou non
                if (goodAnswer.fields.reponse == "oui") {
                    questions[i].answer = 1;
                } else {
                    questions[i].answer = 2;
                }
            }
        }

        setRefresh(!refresh);
        handlePercent();
    }, [reponsesQuestionID, questions])



    //click réponse 
    async function clickReponse(e, reponse) {
        //on check si une réponse existe déjà pour cette question
        const auditId = id;
        const questionNumero = e.fields.id;
        const url = "https://hooks.zapier.com/hooks/catch/7206204/32a4skk/";
        let data = {
            audit_id: id,
            question_numero: questionNumero,
            reponse: reponse
        };
        console.log("data", data);
        let options = {
            method: "POST",
            headers: { "content-type": "application/x-www-form-urlencoded" },
            data: qs.stringify(data),
            url
        };
        let res = await axios(options);
        e.isAnswered = true;
        handlePercent(true);
        setRefresh(!refresh);
    }

    async function toggleIsAnswered(question, reponse) {
        if (reponse === "oui") {
            question.answer = 1;
        } else if (reponse === "non") {
            question.answer = 2;
        }

        setQuestions(questions);
    }

    function handlePercent(fromResponse = false) {
        let answeredQuestions = []

        for (let index = 0; index < questions.length; index++) {
            if (questions[index].isAnswered) {
                answeredQuestions.push(questions[index]);
            }
        }

        if (categories != null) {
            let arrayToAdd = [];
            setPercentsArray([]);
            for (let i = 0; i < categories.length; i++) {
                let questionByType = questions.filter(question => question.fields.categorie_id == categories[i].id);
                let questionByTypeAnswered = questionByType.filter(question => question.isAnswered == true);
                let percentInstance = {
                    "id": categories[i].id,
                    "answered": questionByTypeAnswered.length,
                    "all": questionByType.length
                }

                arrayToAdd.push(percentInstance);


            }
            setPercentsArray(arrayToAdd);
        }
        let newPercent = (answeredQuestions.length / questions.length) * 100;
        setPercent(newPercent);

        if (fromResponse == true) {
            editAuditStatus(newPercent);
        }
    }

    if (!member)
        return (
            <div><Container className="text-center">😕 Vous devez être connecté pour accéder à cette page</Container></div>
        );

    if (!id)
        return (
            <div><Container className="text-center">😕 Aucun audit n'existe pour ce lieu'</Container></div>
        );

    if (member)

        return (
            <>
                {loading === false &&
                    <h3><Loading /></h3>
                }
                {loading === true & questions != null &&
                    <div>
                        <header>
                            <NavbarS light expand="md">
                                <NavbarBrandS href="/">
                                    <img width="50" src="https://assets.website-files.com/63c92fdaaa2630f6c55d3f41/63c936dbf818bd38a251413f_LogoAiTool.svg" alt="logo" className="mb-3" />
                                </NavbarBrandS>
                                <NavbarToggler />
                                <Collapse isOpen={isOpen} navbar>
                                    <Nav className="mr-auto" navbar>
                                        <NavItem>
                                            <NavLink href="/"></NavLink>
                                        </NavItem>
                                    </Nav>
                                    {member && (
                                        <>
                                            <a className="nav-link"
                                                href="#"
                                                onClick={() =>
                                                    memberstack.logout()
                                                        .then(({ data, type }) => {
                                                            window.location.replace('/');
                                                        })}
                                            >
                                                Deconnexion
                                            </a>
                                            <a
                                                outline
                                                className="mr-2 cta"
                                                type="button"
                                                href="/admin"
                                            >
                                                {member.auth.email}
                                            </a>
                                        </>
                                    )}


                                </Collapse>
                            </NavbarS>
                        </header>
                        <div>
                            <Row>
                                <ColCategorie md="4">
                                {
                                            percent === 100 && 
                                            <>
                                            <p>Vous avez répondu à toutes les questions 🥳</p>
                                            
                                            <Link onClick={refreshPage} to={{ pathname: `/resultat/${id}` }} className="btn btn-primary btn-block">Générez vos résultats</Link>
                                            </>
                                        }
                                        {
                                            percent !== 100 && 
                                            <>
                                            <BarreProgression>
                                                <p>Progression</p>
                                                <Progress
                                                    color="warning"
                                                    value={Math.round((percent) * questions.length) / questions.length}
                                                    style={{ marginBottom: "0", width: "100%", zIndex: "0", height: "20px" }}> </Progress>
                                            </BarreProgression>
                                            <div className="text-center" style={{ marginTop: "-21px", zIndex: "!important 10", fontWeight: "bold" }}>{`${Math.round(percent / 100 * questions.length)}/${questions.length}`}</div>
                                            </>
                                        }
                                    
                                    

                                    <Badge color="light" className="mt-4">Catégories du questionnaire {auditInfo.fields.type_nom[0]}</Badge>
                                    <TabsCategorie tabs className='justify-content-center flex-column'>
                                        <>
                                            {categories.map((col, i) => (
                                                <>
                                                    <NavItem key="test">
                                                        <NavLink
                                                            className={classnames({
                                                                active: afficherCategorie.id === categories[i].id
                                                            })}
                                                            onClick={() => { toggle('1'); setAfficherCategorie(categories[i]) }}
                                                        >
                                                            <h5>{categories[i].fields.categorie}</h5>
                                                            <p><small>{percentsArray.find(percent => percent.id == categories[i].id)?.answered} questions / {percentsArray.find(percent => percent.id == categories[i].id)?.all}</small></p>
                                                            <FontAwesomeIcon icon={faAngleRight} />
                                                        </NavLink>
                                                    </NavItem>
                                                </>
                                            ))}
                                        </>
                                        

                                    </TabsCategorie>
                                    <NavCategorieMobile>
                                        <Nav pills
                                        >
                                            {categories.map((col, i) => (
                                                <>
                                                    <NavItem key="test">
                                                        <NavLink
                                                            className={classnames({
                                                                active: afficherCategorie.id === categories[i].id
                                                            })}
                                                            onClick={() => {console.log("test",afficherCategorie); toggle('1'); setAfficherCategorie(categories[i]) }}
                                                        >
                                                            <h5>{categories[i].fields.categorie}</h5>
                                                            <p><small>{percentsArray.find(percent => percent.id == categories[i].id)?.answered} questions / {percentsArray.find(percent => percent.id == categories[i].id)?.all}</small></p>
                                                            <FontAwesomeIcon icon={faAngleRight} />
                                                        </NavLink>
                                                    </NavItem>
                                                </>
                                            ))}

                                        </Nav>
                                    </NavCategorieMobile>
                                    <br/><br/><br/><br/>
                                    <CardHelp/>

                                </ColCategorie>
                                <ColQuestion md="8">
                                    <ContainerQuestion>
                                        <TabContent>
                                            <TabPane>
                                                {questions.map((col, i) => (
                                                    <>
                                                        {questions[i].fields.categorie[0] === afficherCategorie.fields.categorie &&
                                                            <>
                                                                <Question>
                                                                    <div className="bloc-modif">

                                                                        {questions[i].isAnswered === true ?
                                                                            <><p>✔️ déjà répondu</p>
                                                                            <a
                                                                                onClick={() => {
                                                                                    questions[i].isAnswered = false;
                                                                                    handlePercent();
                                                                                    setRefresh(!refresh);
                                                                                }
                                                                                }>
                                                                                Modifier </a>
                                                                                </> : null}
                                                                                </div>
                                                                    {questions[i].isError === true &&
                                                                        <>
                                                                            <Alert style={{ marginLeft: "25px", marginRight: "25px", marginTop: "25px" }} color="danger">Veuillez ajouter un ou plusieurs fichiers</Alert>
                                                                        </>
                                                                    }
                                                                    <div style={questions[i].isAnswered === true ? { opacity: "0.7" } : null}>
                                                                        <div className="d-flex flex-row justify-content-between align-items-start questionCounter">
                                                                            <div className="">
                                                                                Question {questions[i].fields.id}
                                                                            </div>
                                                                        </div>
                                                                        <h2 className="question">
                                                                            {questions[i].fields.question}
                                                                        </h2>
                                                                        <AnswerOptions>
                                                                            <AnswerLabel>
                                                                                <Button
                                                                                    outline
                                                                                    disabled={questions[i].isAnswered === true}
                                                                                    style={questions[i].answer === 1 ? {
                                                                                        backgroundColor: '#3D817B',
                                                                                        color: "white",
                                                                                        borderColor: "#ddd"
                                                                                    } : {
                                                                                        backgroundColor: 'white',
                                                                                        color: "#3D817B", borderColor: "#ddd"
                                                                                    }}
                                                                                    active={questions[i].answer === 1}
                                                                                    onClick={async () => { await toggleIsAnswered(questions[i], "oui"); await clickReponse(questions[i], "oui"); setRefresh(!refresh) }}
                                                                                    value="oui"
                                                                                >
                                                                                    OUI
                                                                                </Button>{" "}
                                                                            </AnswerLabel>
                                                                            <AnswerLabel>
                                                                                <Button
                                                                                    outline
                                                                                    value="non"
                                                                                    disabled={questions[i].isAnswered === true}
                                                                                    style={questions[i].answer === 2 ? {
                                                                                        backgroundColor: '#dc3545',
                                                                                        color: "white",
                                                                                        borderColor: "#ddd"
                                                                                    } : {
                                                                                        backgroundColor: 'white',
                                                                                        color: "#dc3545",
                                                                                        borderColor: "#ddd"
                                                                                    }}
                                                                                    active={questions[i].answer === 2}
                                                                                    onClick={async () => { await toggleIsAnswered(questions[i], "non"), setRefresh(!refresh) }}
                                                                                >
                                                                                    NON
                                                                                </Button>
                                                                            </AnswerLabel>


                                                                        </AnswerOptions>
                                                                        {questions[i].answer === 2 && questions[i].isAnswered !== true ?
                                                                            <BlocPrecisionNon>
                                                                                <Row>
                                                                                    <Col md="12">
                                                                                        <Form>
                                                                                            <FormGroup>
                                                                                                <Label>Veuillez apporter des précisions</Label>
                                                                                                <Input
                                                                                                    onChange={(value) => { questions[i].fields.commentaire = value.target.value }}
                                                                                                    disabled={questions[i].isAnswered === true}
                                                                                                    defaultValue={questions[i].reponse?.fields?.commentaire ?? ""}
                                                                                                    bsSize="sm"
                                                                                                    type="textarea"
                                                                                                    placeholder="Donnez plus de précisions sur l'innaccessibilité"
                                                                                                />
                                                                                            </FormGroup>
                                                                                            <FormGroup>
                                                                                                <Label>Ajoutez une vidéo ou photo</Label><br />
                                                                                                {/* <Button outline disabled={questions[i].isAnswered === true} onClick={() => { setShowPickerOverlay(true), setRefresh(!refresh) }}>
                                                                                                    Ajouter des fichiers
                                                                                                </Button> */}
                                                                                                <PickerOverlay
                                                                                                    apikey={apikey}
                                                                                                    onSuccess={(res) => { onSuccess(res, questions[i]) }}
                                                                                                    pickerOptions={{
                                                                                                        lang: 'fr',
                                                                                                        displayMode: "dropPane",
                                                                                                        fromSources: ['local_file_system', 'url', 'video', 'webcam'],
                                                                                                        maxFiles: 3,
                                                                                                        accept: ['image/*', 'video/*'],
                                                                                                        customText: {
                                                                                                            "Select Files to Upload": "Selectionnez un fichier à telecharger",
                                                                                                            "or Drag and Drop, Copy and Paste Files": "Ou glissez un fichier",
                                                                                                            "Edit": "Modifier",
                                                                                                            "Deselect All": "Tout déselectionner",
                                                                                                            "Upload more": "Ajouter des fichiers",
                                                                                                            "Upload": "Télécharger",
                                                                                                            "My Device": "Ordinateur",
                                                                                                            "Link (URL)": "Lien (URL)",
                                                                                                            "Take Photo": "Prendre une photo",
                                                                                                            "Record Video": "Prendre une video",
                                                                                                            "Click to record audio, video or pick file": "Cliquez ici pour prendre une video ou une photo"
                                                                                                        }
                                                                                                    }}
                                                                                                    render={({ onPick }) => (
                                                                                                        <input
                                                                                                            id="filestack"
                                                                                                            type="file"
                                                                                                            onChange={(e) => {
                                                                                                                const file = e.target.files[0];
                                                                                                                onPick({ file });
                                                                                                            }}
                                                                                                            style={{ display: 'none' }}
                                                                                                        />
                                                                                                    )}
                                                                                                    onError={(error) => {
                                                                                                        console.log(error);
                                                                                                    }}
                                                                                                />
                                                                                                <Row>
                                                                                                    {(questions[i]?.reponse?.fields?.media != null && questions[i]?.reponse?.fields?.media != undefined && questions[i]?.reponse?.fields?.media.length > 0)
                                                                                                        && questions[i]?.reponse?.fields?.media.map(
                                                                                                            (element, index) => {
                                                                                                                console.log(element.type)
                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        {
                                                                                                                            element.type == 'image/jpeg' || element.type == 'image/png' ? <img
                                                                                                                                src={element.url} style={{ height: "50px", marginTop: "20px", marginLeft: "25px" }} /> : <video
                                                                                                                                src={element.url} style={{ height: "50px", marginTop: "20px", marginLeft: "25px" }} />
                                                                                                                        }
                                                                                                                        <br />
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                           
                                                                                                        )
                                                                                                    }
                                                                                                </Row>
                                                                                                {/* <Input
                                                                                        disabled={questions[i].isAnswered === true}
                                                                                        bsSize="sm"
                                                                                        type="file"
                                                                                        onChange={handleUpload}
                                                                                    /> */}
                                                                                                {fileUrl}
                                                                                            </FormGroup>
                                                                                            <Button disabled={questions[i].isAnswered === true} onClick={async () => { await handleUpload(questions[i]); console.log(questions[i]); setRefresh(!refresh) }}>
                                                                                                Valider
                                                                                            </Button>
                                                                                        </Form>
                                                                                    </Col>
                                                                                </Row>
                                                                            </BlocPrecisionNon>
                                                                            : null}
                                                                    </div>
                                                                </Question>
                                                            </>
                                                        }
                                                    </>
                                                ))}

                                            </TabPane>
                                        </TabContent>
                                    </ContainerQuestion>
                                </ColQuestion>
                            </Row>
                        </div>
                    </div>
                }
                <Modal
                    isOpen={urlModal != ''}
                    toggle={() => { setUrlModal('') }}
                >
                    <img src={urlModal} />
                </Modal>

            </>
        );
};

export default Questionnaire;
