import React from "react";
import { Container, Row, Col, CardBody, ListGroup, ListGroupItem } from "reactstrap";
import styled from "styled-components";
import { CardDashboard } from "../styles/Card";
import { Link } from "react-router-dom";


function Dash(props) {
    const member = props.member;
    const userInfo = props.userInfo;

    if (member != null & userInfo != null) {
        return (
            <Container>
                <Row>
                    <Col md="12" align="left">
                        <div className="d-block d-sm-none"><br /><br /></div>
                        <h2>Bonjour {member.customFields.prenom} 👋 </h2>
                        <p>Bienvenue sur Access'up</p>
                    </Col>
                    <Col md="12">
                        <CardDashboard>
                            <CardBody>
                                <Row>
                                    <Col md='12' lg="6">
                                        {userInfo.role === "admin" &&
                                            <>
                                                <VideoDiv className="video">
                                                    <iframe width="100%" height="400px" classnName="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.loom.com%2Fembed%2F3f34a80366c24417838bb21f9e78739b&amp;display_name=Loom&amp;url=https%3A%2F%2Fwww.loom.com%2Fshare%2F3f34a80366c24417838bb21f9e78739b&amp;image=https%3A%2F%2Fcdn.loom.com%2Fsessions%2Fthumbnails%2F3f34a80366c24417838bb21f9e78739b-1658940415593.gif&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=loom" scrolling="no" title="Welcome to Memberstack Vid"></iframe>
                                                </VideoDiv>
                                            </>
                                        }
                                        {userInfo.role === "user" &&
                                            <>
                                                <VideoDiv className="video">
                                                    autre video
                                                </VideoDiv>
                                            </>
                                        }
                                    </Col>
                                    <Col md='12' lg="6">
                                        <ListGroupDash>
                                            <h6>Pour bien commencer suivez le guide</h6>
                                            {userInfo.role === "admin" &&
                                                <>
                                                    <ListGroupItem>
                                                        <Link to="/entreprise">
                                                            <p>
                                                                👉 Renseignez les informations de votre entreprise
                                                                <br /><small>Cela nous permettra entre autre de personnaliser votre audit.</small>
                                                            </p>
                                                        </Link>
                                                    </ListGroupItem>
                                                    <ListGroupItem>
                                                        <Link to="/lieu">
                                                            <p>👉 Renseignez le ou les lieux à auditer
                                                                <br /><small>Pour que vos collaborateurs puissent réaliser leurs audit, il vous faut d'abord enregistrer les lieux à auditer.</small>
                                                            </p>
                                                        </Link>
                                                    </ListGroupItem>
                                                    <ListGroupItem>
                                                        <Link to="/utilisateurs">
                                                            <p>👉 Invitez  vos collaborateurs
                                                                <br /><small>Seule les personnes qui devront réaliser l'audit doivent avoir accès à la plateforme.</small>
                                                            </p>
                                                        </Link>
                                                    </ListGroupItem>
                                                    <ListGroupItem>
                                                        <p> ℹ️ Si vous avez besoin d'aide, prenez rendez-vous avec un expert
                                                            <br /><small>On vous expliquera tout en peu de temps. <a href="https://www.accessup.fr/demo"><b>Prenez rendez-vous</b></a></small>
                                                        </p>
                                                    </ListGroupItem>
                                                </>
                                            }

                                            {userInfo.role === "user" &&
                                                <>
                                                    <ListGroupItem>
                                                        <Link to="/audit">
                                                            <p>
                                                                👉 Rendez-vous sur la page d'audit 
                                                                <br /><small>Vous verrez le ou les audits que vous devez remplir.</small>
                                                            </p>
                                                        </Link>
                                                    </ListGroupItem>
                                                    <ListGroupItem>
                                                        <Link to="/lieu">
                                                            <p>👉 Regardez la vidéo pour bien répondre aux questions
                                                                <br /><small>On vous a préparer un petit tutoriel pour bien répertorier les éventuelles innaccessibilités.</small>
                                                            </p>
                                                        </Link>
                                                    </ListGroupItem>
                                                    <ListGroupItem>
                                                        <p> ℹ️ Si vous avez besoin d'aide, n'hésitez pas à nous contacter
                                                            <br /><small>Via le chat en bas à droite de votre écran, on vous répondra au plus vite.</small>
                                                        </p>
                                                    </ListGroupItem>
                                                </>
                                            }

                                        </ListGroupDash>
                                    </Col>
                                </Row>
                            </CardBody>
                        </CardDashboard>
                    </Col>
                </Row>
            </Container>
        );
    }
    return <>null</>
}

export const VideoDiv = styled.div`
width: 100%;
position: relative;
padding: 0;
border-radius:1rem;
overflow:hidden;
margin-bottom:2rem;
`;

export const ListGroupDash = styled(ListGroup)`
.list-group-item{
    border: 0;
    border-bottom: 1px solid #e9ecef;
    p{
        font-weight:600;
    }
}
`;

export default Dash;
