import React, { createContext, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "./Dashboard";
import Home from "./Home";
import NotFound from "./NotFound"
import Questionnaire from "./Questionnaire";
import Resultat from "./Resultats";

export const userInfoContext = createContext();

const App = () => {
  const [userInfo, setUserInfo] = useState(null);
  return (
    <BrowserRouter history={history}>
    <userInfoContext.Provider value={{ userInfo, setUserInfo }}>
        <Switch>
        <Route path="/" exact user={userInfo} component={Home} />
        
        <Route path="/dashboard" exact user={userInfo} component={Dashboard} />
        <Route path="/entreprise" exact user={userInfo} component={Dashboard} />
        <Route path="/lieu" exact user={userInfo} component={Dashboard} />
        <Route path="/utilisateurs" exact user={userInfo} component={Dashboard} />
        <Route path="/parametres" exact user={userInfo} component={Dashboard} />
        <Route path="/audit" exact user={userInfo} component={Dashboard} />
        <Route path="/questionnaire/:id" exact user={userInfo} component={Questionnaire} />
        <Route path="/resultat/:id" exact user={userInfo} component={Resultat} />
        <Route path='/404' component={NotFound} />
        <Redirect from='*' to='/404' />

            
          </Switch>
    </userInfoContext.Provider>
    </BrowserRouter>
  );
};

export default App;




