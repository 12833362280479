import React from "react";
import { Container, Row, Col, CardBody, Alert } from "reactstrap";
import { CardDashboard } from "../styles/Card";
import { faCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonPrimary } from "../styles/Buttons"
import { MainContainer, Header, PricingContainer, PriceContainer, CurrencyContainer, Price,  PriceText, ButtonContainer,  DataContainer } from "../styles/Pricing";


function Parametres(props) {
    const member = props.member;
    const userInfo = props.userInfo;



    return (
        <Container className="mt-0">
            <Row>
                <Col md="12" align="left">
                    <h2>Facturation</h2>
                    <p>Gérer votre abonement et retrouvez vos factures entreprise.</p>
                </Col>
                <Col md="12">
                    <CardDashboard>
                        <CardBody>
                            <Alert color="primary">
                                <FontAwesomeIcon icon={faInfoCircle} /> Aucune facture disponible pour le moment.
                            </Alert>
                            <p>Nos tarifs par prestation</p>
                            <div className="d-flex flex-row">
                            <MainContainer>
                                <Header>Audit</Header>
                                <PricingContainer>
                                    <PriceContainer>

                                        <Price>
                                            <span> 1 000</span>
                                        </Price>
                                        <CurrencyContainer>
                                            <span>€</span>
                                        </CurrencyContainer>
                                    </PriceContainer>
                                    <PriceText>
                                        <p>
                                            Obtenez votre score d'accessibilité instantannément.
                                        </p>
                                    </PriceText>
                                </PricingContainer>
                                <ButtonContainer>
                                    <ButtonPrimary disabled className="btn-block">Acheter</ButtonPrimary>
                                </ButtonContainer>
                                <DataContainer>
                                    <ul>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} />
                                            Un audit par lieu
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} />
                                            Utilisateur illimité par lieu
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} />
                                            Audit mobile, tablette et desktop
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} />
                                            Score d'accessibilité instantanné
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} />
                                            Export excel et pdf
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} />
                                            Aide et assistance Accessup
                                        </li>
                                    </ul>
                                </DataContainer>
                            </MainContainer>
                            <MainContainer>
                                <Header>Solutions accessibilité</Header>
                                <PricingContainer>
                                    <PriceContainer>

                                        <Price>
                                            <span> Sur devis</span>
                                        </Price>
                                    </PriceContainer>
                                    <PriceText>
                                        <p>
                                            Nos conseillers vous propose des solutions de mise en accessibilité
                                        </p>
                                    </PriceText>
                                </PricingContainer>
                                
                                <ButtonContainer>
                                    <ButtonPrimary disabled className="btn-block">Acheter</ButtonPrimary>
                                </ButtonContainer>
                                
                                <DataContainer>
                                    <ul>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} />
                                            L’exhaustivité des préconisations
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} />
                                            La définition de la responsabilité et du financement des aménagements
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} />
                                            La priorité de mise en accessibilité
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} />
                                            la budgétisation
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} />
                                            Le choix des prestataires
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} />
                                            Le suivi des travaux
                                        </li>
                                    </ul>
                                </DataContainer>
                            </MainContainer>
                            </div>
                        </CardBody>
                    </CardDashboard>
                </Col>

            </Row>
        </Container>
    );
}

export default Parametres;
