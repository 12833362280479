import React, { useEffect, useState, useContext } from "react";
import { userInfoContext } from "../App";
import { Link } from "react-router-dom"
import axios from "axios";
import { Container, Row, Col, CardBody, ListGroup, ListGroupItem, Badge, Button, Alert } from "reactstrap";
import styled from "styled-components";
import { CardDashboard } from "../styles/Card";
import CardHelp from "./CardHelp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPlay } from "@fortawesome/free-solid-svg-icons";
import AuditIcon from '../img/audit.svg';


function Audit(props) {
    const member = props.member;
    const { userInfo, setUserInfo } = useContext(userInfoContext);
    const [auditInfo, setAuditInfo] = useState(null);
    const [auditInfoLoad, setAuditInfoLoad] = useState(false);


    /* on recherche les audits  */
    function checkAuditAdmin() {
        if (userInfo.entreprise_id != undefined) {
            const URLAuditAdmin = 'https://api.airtable.com/v0/appN12t0eZ3JSYwzx/audit';
            const queryAuditAdmin = '?filterByFormula=';
            const filterByAuditAdmin = `SEARCH("${userInfo.entreprise_id[0]}",{entreprise})`;
            const linkAuditAdmin = `${URLAuditAdmin}${queryAuditAdmin}${filterByAuditAdmin}`;

            return axios({
                method: "get",
                url: linkAuditAdmin,
                timeout: 1000 * 5,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer keyOJASKOIpyF1ACT"
                }
            })
                .then((records) => {
                    console.log("records", records.data.records)
                    const allAudit = records.data.records;
                    setAuditInfo(allAudit)
                    console.log("allAudit", allAudit);
                    setAuditInfoLoad(true)
                    return;
                });
        }
    }

    useEffect(() => {
        if (userInfo.entreprise !== "vide") {
            checkAuditAdmin();
        }

        return;
    }, []);

    useEffect(() => {
        setAuditInfo(auditInfo);
    }, auditInfo)

    if (member !== null & userInfo !== null & auditInfoLoad === true) {
        return (
            <>
                {auditInfo.length === 0 &&
                    <>
                        <Container className="mt-0">
                            <Row>
                                <Col md="12">
                                    <CardDashboard>
                                        <Alert color="primary">
                                            <FontAwesomeIcon icon={faInfoCircle} /> Vous n'avez pas encore d'audit actif.
                                        </Alert>
                                    </CardDashboard>
                                </Col>
                            </Row>
                        </Container>
                    </>}

                {/** VU ADMIN - on montre tous les audits de l'entreprise **/}
                {auditInfo != null && userInfo.role === "admin" & auditInfo.length !== 0 &&
                    <>
                        <Container className="mt-0">
                            <Row>
                                <Col md="12" align="left">
                                    <div className="d-block d-sm-none"><br /><br /></div>
                                    <h2>Audit</h2>
                                    <p>Suivez l'avancement  des audits et visualisez les résultats.</p>
                                </Col>

                                <Col md="12">
                                    <CardDashboard>
                                        <CardBody>
                                            <Col md='6' lg="12">
                                                <ListGroupAudit>
                                                    {/* si un seul audit */}
                                                    {auditInfo.length === 1 &&
                                                        <>
                                                            <ListGroupItem>
                                                                <div className="d-flex justify-content-between flex-row align-items-center">
                                                                    <div>
                                                                        <h5>
                                                                            <img src={AuditIcon} alt="audit" />
                                                                            {auditInfo[0].fields.lieu_nom !== undefined && auditInfo[0].fields.lieu_nom[0]}
                                                                        </h5>
                                                                    </div>
                                                                    <div>
                                                                        {auditInfo[0].fields.avancement === "En cours" &&
                                                                            <Badge color="primary">{auditInfo[0].fields.avancement}</Badge>
                                                                        }
                                                                        {auditInfo[0].fields.avancement === "Pas commencé" &&
                                                                            <Badge color="light">{auditInfo[0].fields.avancement}</Badge>
                                                                        }
                                                                        {auditInfo[0].fields.avancement === "Terminé" &&
                                                                            <Badge color="success">{auditInfo[0].fields.avancement}</Badge>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <Row>
                                                                    <Col sm="12" md="9">
                                                                        <div className="d-flex flex-row flex-wrap">
                                                                            <Info>
                                                                                <span>Entreprise</span>
                                                                                {auditInfo[0].fields.entreprise_nom[0]}
                                                                            </Info>
                                                                            <Info>
                                                                                <span>Type d'audit</span>
                                                                                <b>{auditInfo[0].fields.type_nom[0]}</b>
                                                                            </Info>
                                                                            <Info>
                                                                                <span>Utilisateur</span>
                                                                                {auditInfo[0].fields.email_user[0]}
                                                                            </Info>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm="12" md="3" className="mt-2">
                                                                        {auditInfo[0].fields.avancement === "Pas commencé" &&
                                                                            <Link to={{ pathname: `/questionnaire/${auditInfo[0].id}` }} target="_blank" size="md" className="btn btn-outline-secondary btn-block">Commencer <FontAwesomeIcon icon={faPlay} /> </Link>
                                                                        }
                                                                        {auditInfo[0].fields.avancement === "En cours" &&
                                                                            <Link to={{ pathname: `/questionnaire/${auditInfo[0].id}` }} target="_blank" size="md" className="btn btn-outline-secondary btn-block">Reprendre <FontAwesomeIcon icon={faPlay} /> </Link>
                                                                        }
                                                                        {auditInfo[0].fields.avancement === "Terminé" &&
                                                                            <Button color="secondary" outline size="md" className="btn-block">Voir les résultats</Button>
                                                                        }
                                                                    </Col>
                                                                </Row>

                                                            </ListGroupItem>
                                                        </>
                                                    }
                                                    {/* si plusieurs audits */}
                                                    {auditInfo.length > 1 &&
                                                        <>
                                                            {auditInfo.map((col, i) => (
                                                                <>
                                                                    <ListGroupItem>
                                                                        <div className="d-flex justify-content-between flex-row align-items-center">
                                                                            <div>
                                                                                <h5>
                                                                                    <img src={AuditIcon} alt="audit" />
                                                                                    {auditInfo[i].fields.lieu_nom[0]}
                                                                                </h5>
                                                                            </div>
                                                                            <div>
                                                                                {auditInfo[i].fields.avancement === "En cours" &&
                                                                                    <Badge color="primary">{auditInfo[i].fields.avancement}</Badge>
                                                                                }
                                                                                {auditInfo[i].fields.avancement === "Pas commencé" &&
                                                                                    <Badge color="light">{auditInfo[i].fields.avancement}</Badge>
                                                                                }
                                                                                {auditInfo[i].fields.avancement === "Terminé" &&
                                                                                    <Badge color="success">{auditInfo[i].fields.avancement}</Badge>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col sm="12" md="9">
                                                                                <div className="d-flex flex-row flex-wrap">
                                                                                    <Info>
                                                                                        <span>Entreprise</span>
                                                                                        {auditInfo[i].fields.entreprise_nom[0]}
                                                                                    </Info>
                                                                                    <Info>
                                                                                        <span>Type d'audit</span>
                                                                                        {auditInfo[i].fields.type_nom[0]}
                                                                                    </Info>
                                                                                    <Info>
                                                                                        <span>Utilisateur</span>
                                                                                        {auditInfo[i].fields.email_user[0]}
                                                                                    </Info>
                                                                                </div>
                                                                            </Col>
                                                                            <Col sm="12" md="3" className="mt-2">
                                                                                {auditInfo[i].fields.avancement === "Pas commencé" &&
                                                                                    <Link to={{ pathname: `/questionnaire/${auditInfo[i].id}` }} target="_blank" size="md" className="btn btn-outline-secondary btn-block">Commencer <FontAwesomeIcon icon={faPlay} /> </Link>
                                                                                }
                                                                                {auditInfo[i].fields.avancement === "En cours" &&
                                                                                    <Link to={{ pathname: `/questionnaire/${auditInfo[i].id}` }} target="_blank" size="md" className="btn btn-outline-secondary btn-block">Reprendre <FontAwesomeIcon icon={faPlay} /> </Link>
                                                                                }
                                                                                {auditInfo[i].fields.avancement === "Terminé" &&
                                                                                    <Button color="secondary" outline size="md" className="btn-block">Voir les résultats</Button>
                                                                                }
                                                                            </Col>
                                                                        </Row>

                                                                    </ListGroupItem>
                                                                </>
                                                            ))}
                                                        </>
                                                    }
                                                </ListGroupAudit>
                                            </Col>
                                        </CardBody>
                                    </CardDashboard>
                                </Col>
                            </Row>
                        </Container>
                    </>
                }

                {/** VU USER - on montrer les audits liés à l'utilisateurs **/}
                {auditInfo != null && userInfo.role === "user" &&
                    <>
                        <Container className="mt-0">
                            <Row>
                                <Col md="12" align="left">
                                    <div className="d-block d-sm-none"><br /><br /></div>
                                    <h2>Audit</h2>
                                    <p>Remplissez votre audit d'accessibilité</p>
                                </Col>

                                <Col md="12">
                                    <CardDashboard>
                                        <CardBody>
                                            <Col md='6' lg="12">
                                                <CardHelp /><br /><br /><br />
                                                <ListGroupAudit>
                                                    {/* si un seul audit */}
                                                    {auditInfo.length === 1 &&
                                                        <>
                                                            <ListGroupItem>
                                                                <div className="d-flex justify-content-between flex-row align-items-center">
                                                                    <div>
                                                                        <h5>
                                                                            <img src={AuditIcon} alt="audit" />
                                                                            {auditInfo[0].fields.lieu_nom !== undefined && auditInfo[0].fields.lieu_nom[0]}
                                                                        </h5>
                                                                    </div>
                                                                    <div>
                                                                        {auditInfo[0].fields.avancement === "En cours" &&
                                                                            <Badge color="primary">{auditInfo[0].fields.avancement}</Badge>
                                                                        }
                                                                        {auditInfo[0].fields.avancement === "Pas commencé" &&
                                                                            <Badge color="light">{auditInfo[0].fields.avancement}</Badge>
                                                                        }
                                                                        {auditInfo[0].fields.avancement === "Terminé" &&
                                                                            <Badge color="success">{auditInfo[0].fields.avancement}</Badge>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <Row>
                                                                    <Col sm="12" md="9">
                                                                        <div className="d-flex flex-row flex-wrap">
                                                                            <Info>
                                                                                <span>Entreprise</span>
                                                                                {auditInfo[0].fields.entreprise_nom[0]}
                                                                            </Info>
                                                                            <Info>
                                                                                <span>Type d'audit</span>
                                                                                <b>{auditInfo[0].fields.type_nom[0]}</b>
                                                                            </Info>
                                                                            <Info>
                                                                                <span>Utilisateur</span>
                                                                                {auditInfo[0].fields.email_user[0]}
                                                                            </Info>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm="12" md="3" className="mt-2">
                                                                        {auditInfo[0].fields.avancement === "Pas commencé" &&
                                                                            <Link to={{ pathname: `/questionnaire/${auditInfo[0].id}` }} target="_blank" size="md" className="btn btn-outline-secondary btn-block">Commencer <FontAwesomeIcon icon={faPlay} /> </Link>
                                                                        }
                                                                        {auditInfo[0].fields.avancement === "En cours" &&
                                                                            <Link to={{ pathname: `/questionnaire/${auditInfo[0].id}` }} target="_blank" size="md" className="btn btn-outline-secondary btn-block">Reprendre <FontAwesomeIcon icon={faPlay} /> </Link>
                                                                        }
                                                                        {auditInfo[0].fields.avancement === "Terminé" &&
                                                                            <Button color="secondary" outline size="md" className="btn-block">Voir les résultats</Button>
                                                                        }
                                                                    </Col>
                                                                </Row>

                                                            </ListGroupItem>
                                                        </>
                                                    }
                                                    {/* si plusieurs audits */}
                                                    {auditInfo.length > 1 &&
                                                        <>
                                                            {auditInfo.map((col, i) => (
                                                                <>
                                                                    <ListGroupItem>
                                                                        <div className="d-flex justify-content-between flex-row align-items-center">
                                                                            <div>
                                                                                <h5>
                                                                                    <img src={AuditIcon} alt="audit" />
                                                                                    {auditInfo[i].fields.lieu_nom[0]}
                                                                                </h5>
                                                                            </div>
                                                                            <div>
                                                                                {auditInfo[i].fields.avancement === "En cours" &&
                                                                                    <Badge color="primary">{auditInfo[i].fields.avancement}</Badge>
                                                                                }
                                                                                {auditInfo[i].fields.avancement === "Pas commencé" &&
                                                                                    <Badge color="light">{auditInfo[i].fields.avancement}</Badge>
                                                                                }
                                                                                {auditInfo[i].fields.avancement === "Terminé" &&
                                                                                    <Badge color="success">{auditInfo[i].fields.avancement}</Badge>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col sm="12" md="9">
                                                                                <div className="d-flex flex-row flex-wrap">
                                                                                    <Info>
                                                                                        <span>Entreprise</span>
                                                                                        {auditInfo[i].fields.entreprise_nom[0]}
                                                                                    </Info>
                                                                                    <Info>
                                                                                        <span>Type d'audit</span>
                                                                                        {auditInfo[i].fields.type_nom[0]}
                                                                                    </Info>
                                                                                    <Info>
                                                                                        <span>Utilisateur</span>
                                                                                        {auditInfo[i].fields.email_user[0]}
                                                                                    </Info>
                                                                                </div>
                                                                            </Col>
                                                                            <Col sm="12" md="3" className="mt-2">
                                                                                {auditInfo[i].fields.avancement === "Pas commencé" &&
                                                                                    <Link to={{ pathname: `/questionnaire/${auditInfo[i].id}` }} target="_blank" size="md" className="btn btn-outline-secondary btn-block">Commencer <FontAwesomeIcon icon={faPlay} /> </Link>
                                                                                }
                                                                                {auditInfo[i].fields.avancement === "En cours" &&
                                                                                    <Link to={{ pathname: `/questionnaire/${auditInfo[i].id}` }} target="_blank" size="md" className="btn btn-outline-secondary btn-block">Reprendre <FontAwesomeIcon icon={faPlay} /> </Link>
                                                                                }
                                                                                {auditInfo[i].fields.avancement === "Terminé" &&
                                                                                    <Button color="secondary" outline size="md" className="btn-block">Voir les résultats</Button>
                                                                                }
                                                                            </Col>
                                                                        </Row>

                                                                    </ListGroupItem>
                                                                </>
                                                            ))}
                                                        </>
                                                    }
                                                </ListGroupAudit>
                                            </Col>
                                        </CardBody>
                                    </CardDashboard>
                                </Col>
                            </Row>
                        </Container>
                    </>
                }

            </>

        );
    }
    return <>
        <Container className="mt-0">
            <Row>
                <Col md="12">
                    <CardDashboard>
                        <Alert color="primary">
                            <FontAwesomeIcon icon={faInfoCircle} /> Vous n'avez pas encore d'audit actif.
                        </Alert>
                    </CardDashboard>
                </Col>
            </Row>
        </Container>
    </>
}

export const ListGroupAudit = styled(ListGroup)`
.list-group-item{
    margin-bottom: 3rem;
    border-radius: 0.5rem;
    border-top-width:1px!important;
    img{width:48px;height:48px;display: block;margin-bottom: 2rem;}
}

.badge{
    position: absolute;
    right: 12px;
    top: 12px;
    padding: 1%;
    &.badge-primary{
        background-color: #fade6e;
    }
    &.badge-light{
        background-color:#e9ecef;
    }
    &.badge-success{
        background-color:#3e817c73;
    }
}
`;
export const Info = styled.div`
display: flex;
flex-direction: column;
    min-width: 150px;
    margin-right:1.5rem;
span{
    opacity:.5;
}
@media only screen and (max-width: 768px) {
    margin-bottom:1rem;
  }
`;

export default Audit;