import { StrictMode } from "react";
import { MemberstackProvider } from "@memberstack/react"
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "./styles.css";
import { GlobalStyle } from "./styles/GlobalStyle";




const config = {
	publicKey: "pk_sb_a722252594a78a6342e0",
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <MemberstackProvider config={config}>
    <App />
    </MemberstackProvider>
    <GlobalStyle />
  </StrictMode>,
  rootElement
);
