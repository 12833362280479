import React from "react";
import {
    Badge,
    Card,CardBody, CardTitle,Row,Col
} from "reactstrap";
import styled from "styled-components";
import { VideoDiv } from "./Dash";

export const CardHelpS = styled(Card)`
  background-color:#f8f9fa!important;
  border:0;
  border-radius: 1rem;
  
  .card-title{
    font-weight: 700;
    font-size: 1.3rem;
  }
  .video{
    margin-bottom:0!important;
    margin: 0 auto;
}
  .badge-secondary{background-color:#fae4f9!important;color:black;};
  a{
    background-color: #fae4f9;
    padding: 0.3rem 0.5rem;
    border-radius: 100px;
  }

  @media only screen and (max-width: 768px) {
    display:none;
  }
`;

const CardHelp = () => {

  return (
    <>
    <CardHelpS>
        
        <CardBody>
            <Row>
                <Col md="8">
                <Badge>🤔 Aide</Badge>
                    <CardTitle>Comment bien répondre aux questions ? </CardTitle>
                    <p>N'hésitez pas à débuter un chat si vous avez une question</p>
                </Col>
                <Col md="4">
                <VideoDiv className="video">
                                            <iframe width="100%" height="160px" classnName="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.loom.com%2Fembed%2F3f34a80366c24417838bb21f9e78739b&amp;display_name=Loom&amp;url=https%3A%2F%2Fwww.loom.com%2Fshare%2F3f34a80366c24417838bb21f9e78739b&amp;image=https%3A%2F%2Fcdn.loom.com%2Fsessions%2Fthumbnails%2F3f34a80366c24417838bb21f9e78739b-1658940415593.gif&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=loom" scrolling="no" title="Welcome to Memberstack Vid"></iframe>
                                        </VideoDiv>
                </Col>
            </Row>
                    
                
                                       
                                        
        </CardBody>
    </CardHelpS>
    </>
  );
};

export default CardHelp;