import React, { useEffect, useState } from "react";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import { Container, Row, Col, CardBody, Form, FormGroup, Label, Button, Input, Alert, Badge, Card, CardTitle,Modal,ModalHeader,ModalFooter, CardFooter } from "reactstrap";
import { CardDashboard } from "../styles/Card";
import { faCalendar, faEnvelope, faInfoCircle, faMapMarked, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserIcon from "../img/user.svg"


function Utilisateurs(props) {
    const member = props.member;
    const userInfo = props.userInfo;
    const [invitationInfo, setInvitationInfo] = useState(null);
    const [lieuEntrepriseInfo, setLieuEntrepriseInfo] = useState(null);
    const [formAddUser, setFormAddUser] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [formError, setFormError] = useState(false);
    const [modal, setModal] = useState(false);
    const [invitationaSupprimer, setInvitationaSupprimer] = useState(null);

    const toggle = (id) => {
        setModal(!modal);
    }

    /* on recherche les invitations  */
    async function checkInvitationUser() {
        const URLinvitation = 'https://api.airtable.com/v0/appN12t0eZ3JSYwzx/invitation';
        const queryInvit = '?filterByFormula=';
        const filterByInvit = `SEARCH("${userInfo.entreprise}",{entreprise})`;
        const linkInvit = `${URLinvitation}${queryInvit}${filterByInvit}`;

        return await axios({
            method: "get",
            url: linkInvit,
            timeout: 1000 * 5, 
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer keyOJASKOIpyF1ACT"
            }
        })
            .then((records) => {
                console.log(records);
                const allInvit = records.data.records;
                setInvitationInfo(allInvit)
                
                //console.log("allInvit", allInvit);
                return;
            })
            .catch((error) => console.log(error));
        ;
    }

    /* on recherche les lieu de l'entreprise  */
    function checkLieuUser() {
        if(userInfo.entreprise_id != undefined){
            const URLlieu = 'https://api.airtable.com/v0/appN12t0eZ3JSYwzx/lieu';
            const queryLieu = '?filterByFormula=';
            
            const filterByLieu = `SEARCH("${userInfo.entreprise_id[0]}",{entreprise_id})`;
            const linkLieu = `${URLlieu}${queryLieu}${filterByLieu}`;
    
            return axios({
                method: "get",
                url: linkLieu,
                timeout: 1000 * 5, // Wait for 5 seconds
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer keyOJASKOIpyF1ACT"
                }
            })
                .then((records) => {
                    const allLieu = records.data.records;
                    setLieuEntrepriseInfo(records.data.records)
                    //console.log("allLieu", allLieu);
                    return;
                })
                .catch((error) => console.log(error));
        }
    }

    useEffect(() => {
        checkInvitationUser();
        if(userInfo.entreprise !== "vide"){
            checkLieuUser();
        }
        return;
    }, []);

    useEffect(() => {
        setLieuEntrepriseInfo(props.lieuEntreprise);
    }, [props.lieuEntreprise])

    // formulaire new user
    const handleSubmit = (event) => {
        event.preventDefault();

        const url = "https://hooks.zapier.com/hooks/catch/7206204/338n040/";
        let data = {
            airtableUserId: event.target.airtableid.value,
            entreprise: event.target.entreprise.value,
            nom: event.target.nom.value,
            prenom: event.target.prenom.value,
            lieu: event.target.radioLieu.value,
            email: event.target.email.value,
        };
        let options = {
            method: "POST",
            headers: { "content-type": "application/x-www-form-urlencoded" },
            data: qs.stringify(data),
            url
        };

        axios(options)
            .then(async res => {
                
                //console.log("ajout ok", res.data);
                setFormSuccess(true);
                
                setTimeout(async () => {
                    setFormSuccess(false);
                    setFormAddUser(false);
                    await checkInvitationUser();
                }, 3000);
                ;
            })
            .catch(er => {
                //console.log("erreur ajout", er);
                setFormError(true);
                setTimeout(() => {
                    setFormError(false);
                }, 3000);
                ;
            });

    };

    function deleteInvitation(id) {
        console.log("id delete",id);
        return axios({
          method: "delete",
          url: `https://api.airtable.com/v0/appN12t0eZ3JSYwzx/invitation/${id}`,
          headers: {
            Authorization: "Bearer keyOJASKOIpyF1ACT",
            "Content-Type": "application/json"
          }
        })
          .then((response) => {
            setTimeout(() => {
                checkInvitationUser();
                toggle()
            }, 1000);
            ;
          })
          .catch((error) => {
            return error;
          });
      }
    if (lieuEntrepriseInfo?.length === 0) {
        return (
            <Container className="mt-0">
                <Row>
                    <Col md="12">
                        <CardDashboard>
                            <CardBody align="center" className="empty-card">
                                <p>
                                    <span>😔</span> Pour gérer vos utilisateurs vous devez ajouter des lieux.
                                </p>
                            </CardBody>
                        </CardDashboard>-
                    </Col>
                </Row>
            </Container>
        )
    }
    if (formAddUser === true) {
        return (
            <Container className="mt-0">
                <Row>
                    <Col md='9' align="left">
                    <div className="d-block d-sm-none"><br/><br/></div>
                        <h2>Ajouter un utilisateur</h2>
                        <p>Un email d'invitation a rejoindre la plateforme sera envoyé à cet utlisateur.</p>
                    </Col>
                    <Col md='3' align="right">
                        <Button color="light" outline  onClick={() => { setFormAddUser(false); }}>Annuler</Button>
                    </Col>
                    <Col md="12">
                        <CardDashboard>
                            <CardBody>
                                <Form onSubmit={handleSubmit}>
                                    {formSuccess === true &&
                                        <>
                                            <Alert color="success">Invitation envoyée</Alert>
                                        </>
                                    }
                                    {formError === true &&
                                        <>
                                            <Alert color="danger">Une erreur est survenu</Alert>
                                        </>
                                    }
                                    <Input
                                        name="airtableid"
                                        value={member.metaData.airtable_id}
                                        className="d-none"
                                    />
                                    <Input
                                        name="entreprise"
                                        value={userInfo.entreprise}
                                        className="d-none"
                                    />

                                    <FormGroup>
                                        <Label for="">
                                            Nom
                                        </Label>
                                        <Input
                                            name="nom"
                                            placeholder=""
                                            type="text"
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="">
                                            Prénom
                                        </Label>
                                        <Input
                                            name="prenom"
                                            placeholder=""
                                            type="text"
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="">
                                            Email
                                        </Label>
                                        <Input
                                            name="email"
                                            placeholder=""
                                            type="email"
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="selectLieu">
                                            Lieu utilisateur
                                            <br/>
                                            <small>Il s'agit du lieu que l'utilisateur devra auditer.</small>
                                        </Label>
                                        {lieuEntrepriseInfo.map((col, i) => (
                                                 <>
                                                 <FormGroup check>
                                                    <Input
                                                        name="radioLieu"
                                                        type="radio"
                                                        value={lieuEntrepriseInfo[i].id}
                                                        required
                                                    />
                                                    {' '}
                                                    <Label check>
                                                        {lieuEntrepriseInfo[i].fields.nom_lieu}
                                                    </Label>
                                                </FormGroup>
                                                 </>
                                        ))}
                                    </FormGroup>
                                    <Button className="cta btn-block" type="submit">
                                        Valider
                                    </Button>
                                </Form>
                            </CardBody>
                        </CardDashboard>
                    </Col>
                </Row>
            </Container>
        )
    }

    if (userInfo.entreprise !== "vide" & invitationInfo !== null & formAddUser === false) {
        return (
            <Container className="mt-9">
                <Row>
                
                    <Col md='6' lg='9' align="left">
                    <div className="d-block d-sm-none"><br/><br/></div>
                        <h2>Utilisateurs</h2>
                        <p>Gérer les utilisateurs qui font partie de votre entreprise.</p>
                    </Col>
                    <Col md='6' lg='3' align="right">
                        <a href="#" onClick={() => { setFormAddUser(true); }} className="btn cta">+ Ajouter un utilisateur</a>
                        <div className="d-block d-sm-none"><br/><br/></div>
                    </Col>
                    <Col md="12">
                        <CardDashboard>
                            <Alert color="primary">
                                <FontAwesomeIcon icon={faInfoCircle} /> Invitez les collaborateurs qui devront remplir les audits.
                            </Alert>
                            <div className="d-flex flex-row justify-content-evenly align-items-center flex-wrap">
                            {invitationInfo.map((col, i) => (
                                <>
                                        {invitationInfo[i].fields.entreprise === userInfo.entreprise &&
                                            <>
                                                <Card className="m-1 tile"  style={{ minWidth: "300px"}}>
                                                    <CardBody className="p-3">
                                                    <img src={UserIcon} alt="user"/>
                                                        <Badge style={{ position: "absolute", right: "8%" }} color="warning">{invitationInfo[i].fields.Status}</Badge>
                                                        <CardTitle className="mt-4">
                                                            
                                                            <b>{invitationInfo[i].fields.nom} {" "} {invitationInfo[i].fields.prenom}</b>
                                                        </CardTitle>
                                                        <CardBody>
                                                            <p>
                                                            <FontAwesomeIcon icon={faCalendar} className="mr-1" style={{opacity: ".3"}} /> {moment(invitationInfo[i].fields.date_creation).format('DD/MM/YYYY')},
                                                            <br /><FontAwesomeIcon icon={faEnvelope} className="mr-1"  style={{opacity: ".3"}}  /> {invitationInfo[i].fields.email}
                                                            <br /><FontAwesomeIcon icon={faMapMarked} className="mr-1"  style={{opacity: ".3"}}  /> {invitationInfo[i].fields.nom_lieu}
                                                           
                                                            </p>
                                                            <div>
                                                            <Button color="light" outline onClick={()=>{toggle(); setInvitationaSupprimer(invitationInfo[i].id)}}> Supprimer</Button>
                                                            </div>
                                                        </CardBody>
                                                    </CardBody>
                                                </Card>
                                            </>
                                        }
                                </>
                            ))}
                            </div>
                        </CardDashboard>
                    </Col>
                </Row>
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Êtes-vous sûre de vouloir supprimer cet utilisateur ?</ModalHeader>
                    <ModalFooter className="d-flex justify-content-center">
                    <Button color="danger" onClick={()=>{deleteInvitation(invitationaSupprimer)}}>
                        Supprimer
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        Annuler
                    </Button>
                    </ModalFooter>
                </Modal>
            </Container>
            
        )
    }

    


    return (
        <>
        <Container className="mt-0">
                <Row>
                    <Col md="12">
                        <CardDashboard>
                            <CardBody align="center" className="empty-card">
                                <p>
                                    <span>😔</span> Pour gérer vos utilisateurs vous devez ajouter des lieux.
                                </p>
                            </CardBody>
                        </CardDashboard>-
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Utilisateurs;