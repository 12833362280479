import styled from "styled-components";
import { Card} from "reactstrap";

export const CardS = styled(Card)`
border-radius: 8px;
padding:48px;
border:0;
.card-body{
    display: flex;
    width: 100%;
    max-width: 716px;
    margin-right: auto;
    margin-left: auto;
    padding: 48px;
    flex-direction: column;
    border-radius: 8px;
    background-color: #fff;
    text-align: center;
}
@media only screen and (max-width: 768px) {
    padding:10px;
    .card-body{padding: 10px;}
  }
`;

export const CardDashboard = styled(Card)`
border-radius: 8px;
padding:48px;
border:0;
    .card-body{
        display: flex;
        width: 100%;
        padding: 0;
        flex-direction: column;
        border-radius: 8px;
        background-color: #fff;
        @media only screen and (min-width: 900px) {
            form{
                width:50%;
                margin:0 auto;
            }
          }
    }
    @media only screen and (max-width: 768px) {
        padding:2rem;
      }
`;