import styled from "styled-components";
import { Card,ListGroup } from "reactstrap";
import { PieChart } from 'react-minimal-pie-chart';

export const Title = styled.h1`
  font-size: 16px;
  span{
    display: block;
    font-size: 90%;
    opacity: .5;
  }
  
`;
export const CardScore = styled(Card)`
font-size: 16px;
background-color: #f8f9fa;
display: flex;
flex-direction: row;
border: 0;
padding: 4%;
align-items: flex-start;
border-radius: 1rem;
margin-bottom: 1rem;
`;

export const Score = styled.p`
  font-size: 62px;
  font-weight: 800;
  margin-bottom:0;
  small {
    font-size: 20%;
    display: block;
    color: grey;
  }
  &.moteur{
    color:#f0ccef;
  }
  &.visuel{
    color:#3D817B;
  }
  &.auditif{
    color:#F9DE6F;
  }
  &.cognitif{
    color:#898989;
  }
`;
export const Icon = styled.img`
  width: 100px;
`;
export const Divider = styled.hr`
  border: 2px dashed #cecece;
  border-radius: 10rem;
`;
export const ListGroupResultat = styled(ListGroup)`
.list-group-item{
  display:flex;
  font-size:18px;
  span{
    display: contents;
    color: #adb5bd;
  }
  svg{
    width: 20px;
    height: 20px;
    border-radius: 100px;
    margin-right: 0.8rem;
    padding: 0.5rem;
    background-color: #e9ecef;
  }

  &.oui{
    svg{
      color: green;
    }
  }
  &.non{
    svg{
      color: red;
    }
  }
}
  
`;

export const PieChartS = styled(PieChart)`
  height: 200px !important;
  width: 200px !important;
`;
