import React, { useEffect, useState } from "react";
import axios from "axios";
import { useMemberstack } from "@memberstack/react";
import { useParams } from "react-router";
import {
  Badge,
  Container, Row, Col, Card, CardBody, ListGroup, ListGroupItem, Button
} from "reactstrap";
import { checkResult, getReponse, urlQuestions } from './Functions/function-questions';
import { urlAxiosQuestion } from './Functions/function';



{/*style & image*/ }
import { CardDashboard } from "./styles/Card";
import { ButtonPrimary, ButtonGreen } from "./styles/Buttons";
import { Title, CardScore, Score, Icon, Divider, ListGroupResultat, PieChartS } from "./styles/Resultat";
import styled from "styled-components";
import { faCheck, faDownload, faFilePdf, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HandicapMoteur from './img/handicap-moteur.svg';
import HandicapVisuel from './img/handicap-visuel.svg';
import HandicapAuditif from './img/handicap-auditif.svg';
import HandicapCognitif from './img/handicap-cognitif.svg';



const ResultatExemple = () => {
  const { id } = useParams();
  const [auditReponseExistant, setAuditReponseExistant] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [total, setTotal] = useState(null);
  const [allOui, setAllOui] = useState([]);
  const [allNon, setAllNon] = useState([]);
  const [allReponse, setAllReponse] = useState([]);
  /* moteur */
  const [totalQuestionMoteur, setTotalQuestionMoteur] = useState(null);
  const [totalOuiMoteur, setTotalOuiMoteur] = useState(null);
  const [totalNonMoteur, setTotalNonMoteur] = useState(null);
  const [percentOuiMoteur, setPercentOuiMoteur] = useState(null);
  const [percentNonMoteur, setPercentNonMoteur] = useState(null);
  /* visuel */
  const [totalQuestionVisuel, setTotalQuestionVisuel] = useState(null);
  const [totalOuiVisuel, setTotalOuiVisuel] = useState(null);
  const [totalNonVisuel, setTotalNonVisuel] = useState(null);
  const [percentOuiVisuel, setPercentOuiVisuel] = useState(null);
  const [percentNonVisuel, setPercentNonVisuel] = useState(null);
  /* auditif */
  const [totalQuestionAuditif, setTotalQuestionAuditif] = useState(null);
  const [totalOuiAuditif, setTotalOuiAuditif] = useState(null);
  const [totalNonAuditif, setTotalNonAuditif] = useState(null);
  const [percentOuiAuditif, setPercentOuiAuditif] = useState(null);
  const [percentNonAuditif, setPercentNonAuditif] = useState(null);
  /* cognitif */
  const [totalQuestionCognitif, setTotalQuestionCognitif] = useState(null);
  const [totalOuiCognitif, setTotalOuiCognitif] = useState(null);
  const [totalNonCognitif, setTotalNonCognitif] = useState(null);
  const [percentOuiCognitif, setPercentOuiCognitif] = useState(null);
  const [percentNonCognitif, setPercentNonCognitif] = useState(null);

  useEffect(() => {
    // audit non existant
    console.log("on calcul les resultats");
    const getRep = async () => {
      try {
        const response = await getReponse(id);
        setAllReponse(response.data.records);
        setTotal(response.data.records.length);
        setIsLoading(true);
      } catch (e) {
        console.log(e);
      }
    };
    getRep();
  }, []);

  useEffect(() => {
    console.log("all rep ok", allReponse);
    if (allReponse.length > 0) {
      const tableOui = [];
      const tableNon = [];
      for (let i = 0; i < allReponse.length; i++) {
        if (allReponse[i].fields.reponse === "oui") {
          tableOui.push({
            numeroQuestion: allReponse[i].fields.question_numero[0]
          });
        } else if (allReponse[i].fields.reponse === "non") {
          tableNon.push({
            numeroQuestion: allReponse[i].fields.question_numero[0],
            commentaire: allReponse[i].fields.commentaire
          });
        }
      }
      console.log("table oui ", tableOui);
      console.log("table non ", tableNon);

      setAllOui(tableOui);
      setAllNon(tableNon);
      setIsLoading2(true);

    }
  }, [allReponse]);

  
  useEffect(() => {
    console.log("loading2", isLoading2);
    axios.get(urlAxiosQuestion).then((res) => {
      const questionsMoteur = [];
      const questionsVisuel = [];
      const questionsAuditif = [];
      const questionsCognitif = [];

      const allQuestions = res.data.records;
      console.log(allQuestions);
      var i = 0;
      for (i; i < allQuestions.length; i++) {
        //on récupère toutes les questions par type de handicap
        if (allQuestions[i].fields.handicap_moteur === "true") {
          //console.log("oui moteur")
          questionsMoteur.push({
            numeroQuestion: allQuestions[i].fields.id
          });
        }
        if (allQuestions[i].fields.handicap_visuel === "true") {
          questionsVisuel.push({
            numeroQuestion: allQuestions[i].fields.id
          });
        }
        if (allQuestions[i].fields.handicap_auditif === "true") {
          questionsAuditif.push({
            numeroQuestion: allQuestions[i].fields.id
          });
        }
        if (allQuestions[i].fields.handicap_cognitif === "true") {
          questionsCognitif.push({
            numeroQuestion: allQuestions[i].fields.id
          });
        }
      } //end for
      console.log("q moteur", questionsMoteur);
      console.log("q visuel", questionsVisuel);
      console.log("q cognitif", questionsCognitif);
      console.log("q auditif", questionsAuditif);
      console.log("all oui", allOui);

      //HANDICAP MOTEUR oui et non
      const resultOuiMoteur = allOui.filter(function (o1) {
        return questionsMoteur.some(function (o2) {
          return o1.numeroQuestion === o2.numeroQuestion;
        });
      });

      const resultNonMoteur = allNon.filter(function (o1) {
        return questionsMoteur.some(function (o2) {
          return o1.numeroQuestion === o2.numeroQuestion;
        });
      });

      console.log("oui moteur", resultOuiMoteur);
      console.log("non moteur", resultNonMoteur.length);
      //HANDICAP VISUEL oui et non
      const resultOuiVisuel = allOui.filter(function (o1) {
        return questionsVisuel.some(function (o2) {
          return o1.numeroQuestion === o2.numeroQuestion;
        });
      });
      const resultNonVisuel = allNon.filter(function (o1) {
        return questionsVisuel.some(function (o2) {
          return o1.numeroQuestion === o2.numeroQuestion;
        });
      });
      //HANDICAP AUDITIF oui et non
      const resultOuiAuditif = allOui.filter(function (o1) {
        return questionsAuditif.some(function (o2) {
          return o1.numeroQuestion === o2.numeroQuestion;
        });
      });

      const resultNonAuditif = allNon.filter(function (o1) {
        return questionsAuditif.some(function (o2) {
          return o1.numeroQuestion === o2.numeroQuestion;
        });
      });
      console.log("oui auditif", resultOuiAuditif);
      console.log("non auditif", resultNonAuditif);
      //HANDICAP AUDITIF oui et non
      const resultOuiCognitif = allOui.filter(function (o1) {
        return questionsCognitif.some(function (o2) {
          return o1.numeroQuestion === o2.numeroQuestion;
        });
      });
      const resultNonCognitif = allNon.filter(function (o1) {
        return questionsCognitif.some(function (o2) {
          return o1.numeroQuestion === o2.numeroQuestion;
        });
      });

      setTotalQuestionMoteur(questionsMoteur.length);
      setTotalOuiMoteur(resultOuiMoteur.length);
      setTotalNonMoteur(resultNonMoteur.length);
      setTotalQuestionVisuel(questionsVisuel.length);
      setTotalOuiVisuel(resultOuiVisuel.length);
      setTotalNonVisuel(resultNonVisuel.length);

      setTotalQuestionAuditif(questionsAuditif.length);
      setTotalOuiAuditif(resultOuiAuditif.length);
      setTotalNonAuditif(resultNonAuditif.length);

      setTotalQuestionCognitif(questionsCognitif.length);
      setTotalOuiCognitif(resultOuiCognitif.length);
      setTotalNonCognitif(resultNonCognitif.length);

      setIsLoading3(true);
      return;
    })
  }, [isLoading2]);

  
  useEffect(() => {
    console.log("loading3", isLoading3);

    //calcul des poucentages
    console.log("totalOuiMoteur", totalOuiMoteur);
    console.log("totalQuestionMoteur", totalQuestionMoteur);
    const percentOuiMot = Math.trunc(
      (totalOuiMoteur / totalQuestionMoteur) * 100
    );

    setPercentOuiMoteur(percentOuiMot);
    const percentNonMot = Math.trunc(
      (totalNonMoteur / totalQuestionMoteur) * 100
    );
    setPercentNonMoteur(percentNonMot);
    const percentOuiVis = Math.trunc(
      (totalOuiVisuel / totalQuestionVisuel) * 100
    );
    setPercentOuiVisuel(percentOuiVis);
    const percentNonVis = Math.trunc(
      (totalNonVisuel / totalQuestionVisuel) * 100
    );
    setPercentNonVisuel(percentNonVis);
    const percentOuiAudi = Math.trunc(
      (totalOuiAuditif / totalQuestionAuditif) * 100
    );
    setPercentOuiAuditif(percentOuiAudi);
    const percentNonAudi = Math.trunc(
      (totalNonAuditif / totalQuestionAuditif) * 100
    );
    setPercentNonAuditif(percentNonAudi);
    const percentOuiCogni = Math.trunc(
      (totalOuiCognitif / totalQuestionCognitif) * 100
    );
    setPercentOuiCognitif(percentOuiCogni);
    const percentNonCogni = Math.trunc(
      (totalNonCognitif / totalQuestionCognitif) * 100
    );
    setPercentNonCognitif(percentNonCogni);
  }, [totalOuiCognitif]);






  if (totalQuestionCognitif !== null) {
    return (
      <>
        <Container>
          <Row>
            <Col md='6' align="left">
              <h2><span>👋</span> Voici les résultats de votre audit</h2>
            </Col>
            <Col md="6" align="right">

              <Button outline className="mr-2"><FontAwesomeIcon icon={faDownload} className="mr-2" />Télécharger les réponses</Button>
              <ButtonGreen className="mr-2"><FontAwesomeIcon icon={faFilePdf} className="mr-2" />Exporter en PDF</ButtonGreen>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Divider />
            </Col>
          </Row>

          <CardDashboard className="mb-3">
            <CardBody>
              <Row>
                <Col md="12"><Title>HANDICAP MOTEUR <span>{totalQuestionMoteur} questions</span></Title></Col>
                <Col md="6">
                  <CardScore>
                    <Icon src={HandicapMoteur} alt="Handicap moteur" />
                    <Score className="moteur">
                      <small>Score d'accessibilité</small>
                      {percentOuiMoteur}%
                    </Score>
                  </CardScore>
                  <ListGroupResultat horizontal>
                    <ListGroupItem
                      className="oui"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                      {totalOuiMoteur} <span>oui</span>
                    </ListGroupItem>
                    <ListGroupItem
                      className="non"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                      {totalNonMoteur}  <span>non</span>
                    </ListGroupItem>
                  </ListGroupResultat>
                </Col>
                <Col md="6" align="center">
                  <PieChartS
                    data={[
                      { title: 'Oui', value: totalOuiMoteur, color: '#39a6a6' },
                      { title: 'Non', value: totalNonMoteur, color: '#F5F5F5' },
                    ]}
                  />
                </Col>
              </Row>
            </CardBody>
          </CardDashboard>

          <CardDashboard className="mb-3">
            <CardBody>
              <Row>
                <Col md="12"><Title>HANDICAP AUDITIF <span>{totalQuestionAuditif} questions</span></Title></Col>
                <Col md="6">
                  <CardScore>
                    <Icon src={HandicapAuditif} alt="Handicap auditif" />
                    <Score className="auditif">
                      <small>Score d'accessibilité</small>
                      {percentOuiAuditif}%
                    </Score>
                  </CardScore>
                  <ListGroupResultat horizontal>
                    <ListGroupItem
                      className="oui"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                      {totalOuiAuditif} <span>oui</span>
                    </ListGroupItem>
                    <ListGroupItem
                      className="non"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                      {totalNonAuditif} <span>non</span>
                    </ListGroupItem>
                  </ListGroupResultat>
                </Col>
                <Col md="6" align="center">
                  <PieChartS
                    data={[
                      { title: 'Oui', value: totalOuiAuditif, color: '#39a6a6' },
                      { title: 'Non', value: totalNonAuditif, color: '#F5F5F5' },
                    ]}
                  />
                </Col>
              </Row>
            </CardBody>
          </CardDashboard>

          <CardDashboard className="mb-3">
            <CardBody>
              <Row>
                <Col md="12"><Title>HANDICAP VISUEL <span>{totalQuestionVisuel} questions</span></Title></Col>
                <Col md="6">
                  <CardScore>
                    <Icon src={HandicapVisuel} alt="Handicap visuel" />
                    <Score className="visuel">
                      <small>Score d'accessibilité</small>
                      {percentOuiMoteur} %
                    </Score>
                  </CardScore>
                  <ListGroupResultat horizontal>
                    <ListGroupItem
                      className="oui"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                      {totalOuiVisuel} <span>oui</span>
                    </ListGroupItem>
                    <ListGroupItem
                      className="non"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                      {totalNonVisuel}   <span>non</span>
                    </ListGroupItem>
                  </ListGroupResultat>
                </Col>
                <Col md="6">
                  <PieChartS align="center"
                    data={[
                      { title: 'Oui', value: totalOuiVisuel, color: '#39a6a6' },
                      { title: 'Non', value: totalNonVisuel, color: '#F5F5F5' },
                    ]}
                  />
                </Col>
              </Row>
            </CardBody>
          </CardDashboard>

          <CardDashboard className="mb-3">
            <CardBody>
              <Row>
                <Col md="12"><Title>HANDICAP COGNITIF <span>{totalQuestionCognitif} questions</span></Title></Col>
                <Col md="6">
                  <CardScore>
                    <Icon src={HandicapCognitif} alt="Handicap cognitif" />
                    <Score className="cognitif">
                      <small>Score d'accessibilité</small>
                      {percentOuiCognitif} %
                    </Score>
                  </CardScore>
                  <ListGroupResultat horizontal>
                    <ListGroupItem
                      className="oui"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                      {totalOuiCognitif} <span>oui</span>
                    </ListGroupItem>
                    <ListGroupItem
                      className="non"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                      {totalNonCognitif} <span>non</span>
                    </ListGroupItem>
                  </ListGroupResultat>
                </Col>
                <Col md="6">
                  <PieChartS align="center"
                    data={[
                      { title: 'Oui', value: totalOuiCognitif, color: '#39a6a6' },
                      { title: 'Non', value: totalNonCognitif, color: '#F5F5F5' },
                    ]}
                  />
                </Col>
              </Row>
            </CardBody>
          </CardDashboard>

        </Container>
      </>
    )
  }
  else { return <></> }
};

export default ResultatExemple;
