import React, { useState,useEffect } from "react";
import { useMemberstackModal } from "@memberstack/react";
import { useMemberstack } from "@memberstack/react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import {TitleH1,Subtitle} from "./styles/Title";
import {CardS} from "./styles/Card";
import { Redirect } from 'react-router-dom';



function Home() {
    const memberstack = useMemberstack();
    const { openModal, hideModal } = useMemberstackModal();
    const [member, setMember] = useState(null);

    if (member){
        window.location.replace('/dashboard');
    }
    useEffect(() => {
        if (member === null) {
          memberstack.getCurrentMember()
            .then(({ data: member }) => setMember(member))
        }
      }, []);


    return (
        <Container className="grey-bg mt-5">
            <Row>
                <Col md="3" xs="0"></Col>
                <Col md="12" xs="12" lg="6">
                    <CardS>
                        <CardBody align="center">
                            <img width="90" src="https://assets.website-files.com/63c92fdaaa2630f6c55d3f41/63c936dbf818bd38a251413f_LogoAiTool.svg" alt="logo" className="mb-3" style={{margin: "0 auto"}} />
                            <TitleH1>Bienvenue 👋</TitleH1>
                            <Subtitle>Accédez à votre espace personnel AccessUp. </Subtitle>
                        <div className="d-flex flex-row justify-content-around mt-5">
                        
                            <a
                                className="cta outline w-button"
                                onClick={() =>
                                    openModal({
                                        type: "SIGNUP"
                                    }).then(({ data, type }) => {
                                        console.log('data', data);
                                        console.log('type: ', type);
                                        hideModal();
                                        <Redirect to="/dashboard" />
                                        window.location.reload(false);
                                    })
                                }
                                
                            >
                                Inscription
                            </a>
                            <a
                                className="cta w-button"
                                onClick={() =>
                                    openModal({
                                        type: "LOGIN"
                                    }).then(({ data, type }) => {
                                        console.log('type: ', type);
                                        hideModal();
                                        window.location.reload(false);
                                    })
                                }
                            >
                                Connexion
                            </a>
                        </div>
                        </CardBody>
                    </CardS>
                </Col>
            </Row>
        </Container>
    );
}

export default Home;
