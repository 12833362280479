import styled from "styled-components";
import  { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  *,body,html{
    font-family: 'Inter', sans-serif;
  }
  body{
    min-height:100vh;
  }
  iframe{
    position:relative!important;
  }
  a{
    text-decoration:none;
    color:black;
  }
  .empty-card{
    padding:15%!important;
    a{
      width: 50%!important;
      margin: 0 auto!important;
    }
  }
  label{
    display: inline-block;
    margin-bottom: 0rem;
    font-weight: 700;
    font-size: .8rem;
  }
  .form-control:focus {
    border-color: transparent;
    box-shadow: 0 0 0 0.2rem rgb(62 129 123 / 27%);
}
.alert-primary {
  color: #36383c;
  background-color: #fade6e69;
  border-color: transparent;
}
.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 10vh;
  width: 100vw;
  background-color: #f3f5f9;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
}


.loading {
  display: flex;
  min-height: 500px;
  align-items: center;
  justify-content: center;
}

/*buttons bootstrap surcharge*/
.btn-outline-light{
  color: #14171b;
  background-color: rgba(255,255,255,0);
  border-bottom: 1px solid #ddd!important;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  padding: 1%;
  transition: opacity .2s;
  border: 0;
    &:hover{
      border-bottom-color:#fade6e!important;
      background-color: rgba(255,255,255,0);
      color:#fade6e!important;
    }
}

.tile {
  border-radius: 8px;
  padding: 32px;
  .card-body{background-color:none!important;}
  img{width: 48px;height: 48px;margin-bottom:2rem;}
}

.badge{
  border-top-right-radius: 50%;
  border-top-left-radius: 1%;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 1%;
  padding:3%;
}

  
`;